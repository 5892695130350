import ArrayType from '@api/posMiddlewareCore/types/ArrayType';
import StringType from '@api/posMiddlewareCore/types/StringType';

export default {
  name: {
    type: new StringType(),
  },
  roles: {
    type: new ArrayType(new StringType()),
  },
};
