

import {
  computed,
  defineComponent,
} from 'vue';
import {useI18n} from 'vue-i18n';
import Button from './button/Button.vue';
import Modal from './modal/Modal.vue';

export default defineComponent({
  name: 'ModalJsonEditHelp',
  components: {
    Button,
    Modal,
  },
  inheritAttrs: false,
  props: {
    entity: {
      type: null,
      required: true,
    },
  },
  emits: ['update:entity', 'save'],
  setup(props, {emit}) {
    const i18n = useI18n();

    const configuration = computed({
      get() {
        return props.entity;
      },
      set(val) {
        emit('update:entity', val);
      },
    });

    const onClose = () => {
      configuration.value = null;
    };

    const open = computed(() => {
      return !!configuration.value;
    });

    const title = computed(() => {
      return i18n.t('form.jsonInput.helpModal.title');
    });

    return {
      onClose,
      open,
      title,
    };
  },
});
