import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "typo-h4 pb-6"
}
const _hoisted_2 = {
  key: 1,
  class: "pb-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$slots.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "title")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.subTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "subTitle")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["grid gap-6", {
        'grid-cols-1': _ctx.isLayoutSingle,
        'grid-cols-1 sm:grid-cols-2': _ctx.isLayoutBase,
        'grid-cols-1 sm:grid-cols-3': _ctx.isLayoutCompressed,
        'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4': _ctx.isLayoutDetailed,
      }])
    }, [
      _renderSlot(_ctx.$slots, "default", {
        formReadonly: _ctx.formReadonly,
        formDisabled: _ctx.formDisabled
      })
    ], 2)
  ]))
}