
import {
  HLInputText,
} from '@designeo/vue-forms';
import {inputEvents, inputProps} from '@ui/components/input/input';
import ModalJsonEditHelp from '@ui/components/ModalJsonEditHelp.vue';
import PhIcon from '@ui/components/PhIcon.vue';
import {useInput} from '@ui/helpers/form';
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';
import MonacoEditor from '../MonacoEditor.vue';

export default defineComponent({
  name: 'InputJson',
  components: {
    ModalJsonEditHelp,
    PhIcon,
    MonacoEditor,
    HLInputText,
  },
  inheritAttrs: false,
  props: {
    ...inputProps,
    schema: {
      type: Object as PropType<{ [key: string]: any }>,
      default: null,
    },
    showHelp: {
      type: Boolean,
      default: true,
    },
  },
  emits: [...inputEvents],
  setup(props, {emit}) {
    const inputRef = ref(null);
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const modalEditHelp = ref<boolean>(false);

    const {
      valid,
      field,
      focused,
      disabled,
    } = useInput(props);

    const onHelpClicked = () => {
      modalEditHelp.value = true;
    };

    return {
      inputRef,
      model,
      valid,
      field,
      focused,
      disabled,
      onHelpClicked,
      modalEditHelp,
    };
  },
});
