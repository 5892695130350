export default {
  cs: {
    pointOfSaleConfiguration: {
      fields: {
        versionNumber: 'Verze',
        createdDate: 'Datum vytvoření',
        createdByName: 'Vytvořil/a',
        created: 'Vytvořeno',
        state: 'Stav',
        schemaId: 'Schéma',
        data: 'Konfigurace',
      },
      grid: {
        actions: {
          archive: {
            confirm: 'Opravdu chcete konfiguraci archivovat?',
          },
        },
      },
      form: {
        create: {
          title: 'Nová konfigurace',
        },
        update: {
          title: 'Editace konfigurace',
        },
        readonly: {
          title: 'Náhled konfigurace',
        },
        placeholders: {
          schemaId: 'Vyberte schéma',
        },
        actions: {
          switchToEdit: 'Přepnout do editace',
        },
      },
    },
  },
  sk: {
    pointOfSaleConfiguration: {
      fields: {
        versionNumber: 'Verzia',
        createdDate: 'Dátum vytvorenia',
        createdByName: 'Vytvoril/a',
        created: 'Vytvorené',
        state: 'Stav',
        schemaId: 'Schéma',
        data: 'Konfigurácia',
      },
      grid: {
        actions: {
          archive: {
            confirm: 'Naozaj chcete archivovať konfiguráciu?',
          },
        },
      },
      form: {
        create: {
          title: 'Nová konfigurácia',
        },
        update: {
          title: 'Úprava konfigurácie',
        },
        readonly: {
          title: 'Náhľad konfigurácie',
        },
        placeholders: {
          schemaId: 'Vyberte schému',
        },
        actions: {
          switchToEdit: 'Prepnúť do úpravy',
        },
      },
    },
  },
  en: {
    pointOfSaleConfiguration: {
      fields: {
        versionNumber: 'Version',
        createdDate: 'Created date',
        createdByName: 'Created by',
        created: 'Created',
        state: 'State',
        schemaId: 'Schema',
        data: 'Configuration',
      },
      grid: {
        actions: {
          archive: {
            confirm: 'Are you sure you want to archive this configuration?',
          },
        },
      },
      form: {
        create: {
          title: 'New configuration',
        },
        update: {
          title: 'Edit configuration',
        },
        readonly: {
          title: 'Configuration preview',
        },
        placeholders: {
          schemaId: 'Select schema',
        },
        actions: {
          switchToEdit: 'Switch to edit',
        },
      },
    },
  },
  de: {
    pointOfSaleConfiguration: {
      fields: {
        versionNumber: 'Version',
        createdDate: 'Erstellungsdatum',
        createdByName: 'Erstellt von',
        created: 'Erstellt am',
        state: 'Status',
        schemaId: 'Schema',
        data: 'Konfiguration',
      },
      grid: {
        actions: {
          archive: {
            confirm: 'Möchten Sie die Konfiguration wirklich archivieren?',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Konfiguration',
        },
        update: {
          title: 'Konfiguration bearbeiten',
        },
        readonly: {
          title: 'Konfigurationsvorschau',
        },
        placeholders: {
          schemaId: 'Schema auswählen',
        },
        actions: {
          switchToEdit: 'Zur Bearbeitung wechseln',
        },
      },
    },
  },
};
