import {ConfigurationState} from '@api/posMiddlewareCore/constants/configurationState';

export default {
  cs: {
    configuration: {
      detail: {
        title: 'Konfigurace - {name}',
        actions: {
          posAttributesAssign: {
            title: 'Přiřadit na POS',
          },
          compatibility: {
            title: 'Kompatibilita',
          },
          archive: {
            confirm: 'Opravdu chcete konfiguraci archivovat?',
          },
        },
        tabs: {
          assignedPos: {
            title: 'Přiřazené pokladny',
          },
          configurationPreview: {
            title: 'Náhled',
          },
        },
      },
      grid: {
        title: 'Konfigurace',
        filters: {
          name: 'Vyhledat',
          data: 'Vyhledat v konfiguraci',
          state: 'Stav',
          activeAttributeIds: 'POS atributy (pouze pro aktivní konfigurace)',
          createdByName: 'Autor',
        },
        actions: {
          create: {
            title: 'Nová konfigurace',
          },
          compatibility: {
            title: 'Kompatibilita',
          },
        },
        advancedFilters: {
          title: 'Filtrovat konfigurace',
          placeholders: {
            name: 'Zadejte název',
            data: 'Zadejte text',
            state: 'Vyberte stav',
            activeAttributeIds: 'Vyberte atributy',
            createdByName: 'Zadejte autora',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvoření nové konfigurace',
        },
        readonly: {
          title: 'Konfigurace {name}',
        },
        update: {
          title: 'Editace konfigurace',
          actions: {
            archivePreviousAndReassign: {
              title: 'Uložit a archivovat předchozí',
            },
            saveDraft: {
              title: 'Uložit koncept',
            },
          },
        },
        placeholders: {
          name: 'Zadejte název',
          priority: 'Zadejte prioritu',
          schemaId: 'Vyberte schéma',
        },
        tabs: {
          editor: {
            title: 'Editor',
            unusable: 'Verze schématu {currentSchemaVersion} není podporovaná, použijte verzi {supportedSchemaBreakpoint} nebo vyšší.',
            buttons: {
              showPreview: {
                title: 'Zobrazit náhled konfigurace',
              },
              hidePreview: {
                title: 'Skrýt náhled konfigurace',
              },
            },
          },
          code: {
            title: 'Kód',
          },
          detail: {
            title: 'Detail',
          },
        },
      },
      fields: {
        name: 'Název',
        priority: 'Priorita',
        versionNumber: 'Číslo verze',
        state: 'Stav',
        stateEnum: {
          [ConfigurationState.enabled]: 'Povolený',
          [ConfigurationState.active]: 'Aktivní',
          [ConfigurationState.draft]: 'Koncept',
          [ConfigurationState.archive]: 'Archiv',
        },
        schemaId: 'Verze schéma',
        assignments: 'Přiřazení',
        createdByName: 'Vytvořil',
        createdDate: 'Datum vytvoření',
        data: 'Data',
        publishedDate: 'Publikováno',
        created: 'Vytvořeno',
      },
      modalConfigurationCompatibility: {
        title: 'Kompatibilita konfigurace {name}',
        grid: {
          semVersion: 'Verze schéma',
          isDefault: 'Defaultní',
          schemaIsValid: 'Validní schéma',
          configurationIsValid: 'Validní konfigurace',
          evaluate: 'Vyhodnotit',
          actions: {
            checkCompatibility: {
              title: 'Ověřit kompatibilitu',
            },
          },
        },
      },
    },
  },
  sk: {
    configuration: {
      detail: {
        title: 'Konfigurácia - {name}',
        actions: {
          posAttributesAssign: {
            title: 'Priradiť na POS',
          },
          compatibility: {
            title: 'Kompatibilita',
          },
          archive: {
            confirm: 'Naozaj chcete archivovať konfiguráciu?',
          },
        },
        tabs: {
          assignedPos: {
            title: 'Priradené pokladne',
          },
          configurationPreview: {
            title: 'Náhľad',
          },
        },
      },
      grid: {
        title: 'Konfigurácia',
        filters: {
          name: 'Vyhľadať',
          data: 'Vyhľadať v konfigurácii',
          state: 'Stav',
          activeAttributeIds: 'POS atribúty (len pre aktívne konfigurácie)',
          createdByName: 'Autor',
        },
        actions: {
          create: {
            title: 'Nová konfigurácia',
          },
          compatibility: {
            title: 'Kompatibilita',
          },
        },
        advancedFilters: {
          title: 'Filtrovať konfigurácie',
          placeholders: {
            name: 'Zadajte názov',
            data: 'Zadajte text',
            state: 'Vyberte stav',
            activeAttributeIds: 'Vyberte atribúty',
            createdByName: 'Zadajte autora',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvorenie novej konfigurácie',
        },
        readonly: {
          title: 'Konfigurácia {name}',
        },
        update: {
          title: 'Editácia konfigurácie',
          actions: {
            archivePreviousAndReassign: {
              title: 'Uložiť a archivovať predchádzajúcu',
            },
            saveDraft: {
              title: 'Uložit koncept',
            },
          },
        },
        placeholders: {
          name: 'Zadajte názov',
          priority: 'Zadajte prioritu',
          schemaId: 'Vyberte schéma',
        },
        tabs: {
          editor: {
            title: 'Editor',
            unusable: 'Verzia schémy {currentSchemaVersion} nie je podporovaná, použite verziu {supportedSchemaBreakpoint} alebo vyššiu.',
            buttons: {
              showPreview: {
                title: 'Zobraziť náhľad konfigurácie',
              },
              hidePreview: {
                title: 'Skryť náhľad konfigurácie',
              },
            },
          },
          code: {
            title: 'Kód',
          },
          detail: {
            title: 'Detail',
          },
        },
      },
      fields: {
        name: 'Názov',
        priority: 'Priorita',
        versionNumber: 'Číslo verzie',
        state: 'Stav',
        stateEnum: {
          [ConfigurationState.enabled]: 'Povolený',
          [ConfigurationState.active]: 'Aktívny',
          [ConfigurationState.draft]: 'Koncept',
          [ConfigurationState.archive]: 'Archív',
        },
        schemaId: 'Verzia schéma',
        assignments: 'Priradenia',
        createdByName: 'Vytvoril',
        createdDate: 'Dátum vytvorenia',
        data: 'Dáta',
        publishedDate: 'Publikované',
        created: 'Vytvorené',
      },
      modalConfigurationCompatibility: {
        title: 'Kompatibilita konfigurácie {name}',
        grid: {
          semVersion: 'Verzia schémy',
          isDefault: 'Predvolené',
          schemaIsValid: 'Platná schéma',
          configurationIsValid: 'Platná konfigurácia',
          evaluate: 'Vyhodnotiť',
          actions: {
            checkCompatibility: {
              title: 'Overiť kompatibilitu',
            },
          },
        },
      },
    },
  },
  en: {
    configuration: {
      detail: {
        title: 'Configuration - {name}',
        actions: {
          posAttributesAssign: {
            title: 'Assign to POS',
          },
          compatibility: {
            title: 'Compatibility',
          },
          archive: {
            confirm: 'Do you really want to archive configuration?',
          },
        },
        tabs: {
          assignedPos: {
            title: 'Assigned POS',
          },
          configurationPreview: {
            title: 'Preview',
          },
        },
      },
      grid: {
        title: 'Configurations',
        filters: {
          name: 'Search',
          data: 'Search in configuration',
          state: 'State',
          activeAttributeIds: 'POS attributes (only for active configurations)',
          createdByName: 'Author',
        },
        actions: {
          create: {
            title: 'New configuration',
          },
          compatibility: {
            title: 'Compatibility',
          },
        },
        advancedFilters: {
          title: 'Filter configurations',
          placeholders: {
            name: 'Enter name',
            data: 'Enter text',
            state: 'Select state',
            activeAttributeIds: 'Select attributes',
            createdByName: 'Enter author',
          },
        },
      },
      form: {
        create: {
          title: 'Create new configuration',
        },
        readonly: {
          title: 'Configuration {name}',
        },
        update: {
          title: 'Edit configuration',
          actions: {
            archivePreviousAndReassign: {
              title: 'Save and archive previous',
            },
            saveDraft: {
              title: 'Save draft',
            },
          },
        },
        placeholders: {
          name: 'Enter name',
          priority: 'Enter priority',
          schemaId: 'Select schema',
        },
        tabs: {
          editor: {
            title: 'Editor',
            unusable: 'Schema version {currentSchemaVersion} is not supported, use version {supportedSchemaBreakpoint} or higher.',
            buttons: {
              showPreview: {
                title: 'Show configuration preview',
              },
              hidePreview: {
                title: 'Hide configuration preview',
              },
            },
          },
          code: {
            title: 'Code',
          },
          detail: {
            title: 'Detail',
          },
        },
      },
      fields: {
        name: 'Name',
        priority: 'Priority',
        versionNumber: 'Version number',
        state: 'State',
        stateEnum: {
          [ConfigurationState.enabled]: 'Enabled',
          [ConfigurationState.active]: 'Active',
          [ConfigurationState.draft]: 'Draft',
          [ConfigurationState.archive]: 'Archive',
        },
        schemaId: 'Schema version',
        assignments: 'Assignments',
        createdByName: 'Created by',
        createdDate: 'Created date',
        data: 'Data',
        publishedDate: 'Published',
        created: 'Created',
      },
      modalConfigurationCompatibility: {
        title: 'Configuration compatibility {name}',
        grid: {
          semVersion: 'Schema version',
          isDefault: 'Default',
          schemaIsValid: 'Valid schema',
          configurationIsValid: 'Valid configuration',
          evaluate: 'Evaluate',
          actions: {
            checkCompatibility: {
              title: 'Check compatibility',
            },
          },
        },
      },
    },
  },
  de: {
    configuration: {
      detail: {
        title: 'Konfiguration - {name}',
        actions: {
          posAttributesAssign: {
            title: 'POS zuweisen',
          },
          compatibility: {
            title: 'Kompatibilität',
          },
          archive: {
            confirm: 'Möchten Sie die Konfiguration wirklich archivieren?',
          },
        },
        tabs: {
          assignedPos: {
            title: 'Zugewiesene Kassen',
          },
          configurationPreview: {
            title: 'Vorschau',
          },
        },
      },
      grid: {
        title: 'Konfiguration',
        filters: {
          name: 'Suchen',
          data: 'In der Konfiguration suchen',
          state: 'Status',
          activeAttributeIds: 'POS Attribute (nur für aktive Konfigurationen)',
          createdByName: 'Autor',
        },
        actions: {
          create: {
            title: 'Neue Konfiguration',
          },
          compatibility: {
            title: 'Kompatibilität',
          },
        },
        advancedFilters: {
          title: 'Konfigurationen filtern',
          placeholders: {
            name: 'Geben Sie einen Namen ein',
            data: 'Geben Sie einen Text ein',
            state: 'Wählen Sie einen Status',
            activeAttributeIds: 'Wählen Sie Attribute',
            createdByName: 'Geben Sie einen Autor ein',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Konfiguration erstellen',
        },
        readonly: {
          title: 'Konfiguration {name}',
        },
        update: {
          title: 'Konfiguration bearbeiten',
          actions: {
            archivePreviousAndReassign: {
              title: 'Vorherige speichern und archivieren',
            },
            saveDraft: {
              title: 'Entwurf speichern',
            },
          },
        },
        placeholders: {
          name: 'Geben Sie einen Namen ein',
          priority: 'Geben Sie eine Priorität ein',
          schemaId: 'Wählen Sie ein Schema',
        },
        tabs: {
          editor: {
            title: 'Editor',
            unusable: 'Schema-Version {currentSchemaVersion} wird nicht unterstützt. Verwenden Sie Version {supportedSchemaBreakpoint} oder höher.',
            buttons: {
              showPreview: {
                title: 'Konfigurationsvorschau anzeigen',
              },
              hidePreview: {
                title: 'Konfigurationsvorschau ausblenden',
              },
            },
          },
          code: {
            title: 'Code',
          },
          detail: {
            title: 'Detail',
          },
        },
      },
      fields: {
        name: 'Name',
        priority: 'Priorität',
        versionNumber: 'Versionsnummer',
        state: 'Status',
        stateEnum: {
          [ConfigurationState.enabled]: 'Aktiviert',
          [ConfigurationState.active]: 'Aktiv',
          [ConfigurationState.draft]: 'Entwurf',
          [ConfigurationState.archive]: 'Archiv',
        },
        schemaId: 'Schema-Version',
        assignments: 'Zuordnungen',
        createdByName: 'Erstellt von',
        createdDate: 'Erstellungsdatum',
        data: 'Daten',
        publishedDate: 'Veröffentlicht',
        created: 'Erstellt',
      },
      modalConfigurationCompatibility: {
        title: 'Kompatibilität der Konfiguration {name}',
        grid: {
          semVersion: 'Schema-Version',
          isDefault: 'Standard',
          schemaIsValid: 'Gültiges Schema',
          configurationIsValid: 'Gültige Konfiguration',
          evaluate: 'Auswerten',
          actions: {
            checkCompatibility: {
              title: 'Kompatibilität überprüfen',
            },
          },
        },
      },
    },
  },
};
