import {emitTestEvent} from '@ui/helpers/testEvent';
import {initYup} from '@ui/helpers/validations';
import {useFeatureStore} from '@ui/modules/Core/store/FeatureStore';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {App} from 'vue';
import routes from './routes';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type CoreRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
  preloadHooks: any[],
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $hasActiveFeature: (featureName: string) => boolean,
  }
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = CoreRequiredContext & {
}

export function registerCoreModule({FeaturesCtor = null} = {}) {
  return async function(ctx: CoreRequiredContext) {
    initYup();

    await ctx.registerRoutes(routes);

    await ctx.registerTranslations(translations);

    await ctx.registerHookAfterEach((to) => {
      for (const matchedRoute of to.matched) {
        if (matchedRoute.path === '/') {
          continue;
        }

        emitTestEvent(`${TestEvent.routerNavigation}:${matchedRoute.name as string ?? matchedRoute.path}`);
      }
      emitTestEvent(`${TestEvent.routerNavigation}:${to.fullPath as string}`);
    });

    if (FeaturesCtor) {
      const featureStore = useFeatureStore();

      document.addEventListener('keyup', (event) => {
        if (event.key === 'F' && event.ctrlKey && event.shiftKey && event.altKey) {
          featureStore.openForm(new FeaturesCtor({}));
        }
      });

      ctx.app.use({
        install: (app) => {
          Object.defineProperty(app.config.globalProperties, '$hasActiveFeature', {
            value: (featureName: string) => {
              return featureStore.hasActiveFeature(featureName);
            },
          });
        },
      });
    }
  };
}


