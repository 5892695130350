
import {injectFormContext} from '@designeo/vue-forms/src/Form/Form';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'FormContainer',
  setup() {
    const {
      readonly: formReadonly,
      disabled: formDisabled,
    } = injectFormContext();

    return {
      formReadonly,
      formDisabled,
    };
  },
});
