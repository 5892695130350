
export default {
  cs: {
    promotionList: {
      detail: {
        title: 'Promoční koš',
        suitableArticles: {
          title: 'Zobrazit vyhovující artikly',
          grid: {
            filters: {
              search: 'Vyhledat (min. 2 znaky)',
            },
            columns: {
              text: 'Název',
              internalNumber: 'Interní číslo',
              gtin: 'GTIN',
            },
            advancedFilters: {
              title: 'Advanced filters',
              placeholders: {
                search: 'Vyhledat',
              },
            },
          },
        },
      },
      grid: {
        title: 'Promoční koše',
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
        },
        actions: {
          create: {
            title: 'Založit promoční koš',
          },
          bulkDelete: {
            title: 'Hromadné mazání',
            confirm: 'Opravdu si přejete hromadně smazat vybrané záznamy?',
            success: 'Záznamy byly úspěšně smazány',
          },
        },
      },
      form: {
        create: {
          title: 'Nový promoční koš',
        },
        update: {
          title: 'Editace promočního koše',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
        },
      },
      fields: {
        id: 'Unikátní identifikátor',
        name: 'Název',
        code: 'Kód',
        editedDate: 'Datum poslední úpravy',
        edited: 'Upraveno',
        listDefinition: 'Definice seznamu',
      },
    },
  },
  sk: {
    promotionList: {
      detail: {
        title: 'Zoznam promočí',
        suitableArticles: {
          title: 'Zobraziť vhodné produkty',
          grid: {
            filters: {
              search: 'Vyhľadať (min. 2 znaky)',
            },
            columns: {
              text: 'Názov',
              internalNumber: 'Interné číslo',
              gtin: 'GTIN',
            },
            advancedFilters: {
              title: 'Pokročilé filtre',
              placeholders: {
                search: 'Vyhľadať',
              },
            },
          },
        },
      },
      grid: {
        title: 'Zoznamy promočí',
        filters: {
          search: 'Vyhľadať (min. 2 znaky)',
          code: 'Kód',
        },
        actions: {
          create: {
            title: 'Vytvoriť promočný koš',
          },
          bulkDelete: {
            title: 'Hromadné vymazanie',
            confirm: 'Naozaj chcete hromadne vymazať vybrané záznamy?',
            success: 'Záznamy boli úspešne vymazané',
          },
        },
      },
      form: {
        create: {
          title: 'Nový promočný koš',
        },
        update: {
          title: 'Úprava promočného koša',
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód',
        },
      },
      fields: {
        id: 'Jedinečný identifikátor',
        name: 'Názov',
        code: 'Kód',
        editedDate: 'Dátum poslednej úpravy',
        edited: 'Upravené',
        listDefinition: 'Definícia zoznamu',
      },
    },
  },
  en: {
    promotionList: {
      detail: {
        title: 'Promotion list',
        suitableArticles: {
          title: 'Show suitable articles',
          grid: {
            filters: {
              search: 'Search (min. 2 characters)',
            },
            columns: {
              text: 'Name',
              internalNumber: 'Internal number',
              gtin: 'GTIN',
            },
            advancedFilters: {
              title: 'Advanced filters',
              placeholders: {
                search: 'Search',
              },
            },
          },
        },
      },
      grid: {
        title: 'Promotion lists',
        filters: {
          search: 'Search (min. 2 characters)',
          code: 'Code',
        },
        actions: {
          create: {
            title: 'Create promotion list',
          },
          bulkDelete: {
            title: 'Bulk delete',
            confirm: 'Are you sure you want to delete selected records?',
            success: 'Records were successfully deleted',
          },
        },
      },
      form: {
        create: {
          title: 'New promotion list',
        },
        update: {
          title: 'Edit promotion list',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
        },
      },
      fields: {
        id: 'Unique identifier',
        name: 'Name',
        code: 'Code',
        editedDate: 'Edited date',
        edited: 'Edited',
        listDefinition: 'List definition',
      },
    },
  },
  de: {
    promotionList: {
      detail: {
        title: 'Aktionsliste',
        suitableArticles: {
          title: 'Zeige passende Artikel',
          grid: {
            filters: {
              search: 'Suchen (min. 2 Zeichen)',
            },
            columns: {
              text: 'Name',
              internalNumber: 'Interne Nummer',
              gtin: 'GTIN',
            },
            advancedFilters: {
              title: 'Erweiterte Filter',
              placeholders: {
                search: 'Suchen',
              },
            },
          },
        },
      },
      grid: {
        title: 'Aktionslisten',
        filters: {
          search: 'Suchen (min. 2 Zeichen)',
          code: 'Code',
        },
        actions: {
          create: {
            title: 'Aktionsliste erstellen',
          },
          bulkDelete: {
            title: 'Massenlöschung',
            confirm: 'Wollen Sie die ausgewählten Datensätze wirklich massenweise löschen?',
            success: 'Datensätze wurden erfolgreich gelöscht',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Aktionsliste',
        },
        update: {
          title: 'Bearbeitung der Aktionsliste',
        },
        placeholders: {
          name: 'Namen eingeben',
          code: 'Code eingeben',
        },
      },
      fields: {
        id: 'Eindeutige Kennung',
        name: 'Name',
        code: 'Code',
        editedDate: 'Datum der letzten Bearbeitung',
        edited: 'Bearbeitet',
        listDefinition: 'Definition der Liste',
      },
    },
  },
};
