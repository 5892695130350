import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputJson = _resolveComponent("InputJson")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormContainerSection = _resolveComponent("FormContainerSection")!
  const _component_FormContainer = _resolveComponent("FormContainer")!
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.isOpen,
    silenceCloseListeners: true,
    class: "modal--6xl",
    onClose: _ctx.onClose
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    bodyWrapper: _withCtx(() => [
      (_ctx.isOpen)
        ? (_openBlock(), _createBlock(_component_Form, _mergeProps({ key: 0 }, _ctx.form.toProps(), {
            useClone: false,
            [(_ctx.$test.form) || ""]: 'feature',
            onSubmit: _ctx.onSubmit
          }), {
            default: _withCtx(({isValid}) => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormContainer, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormContainerSection, { layout: _ctx.formContainerSectionLayout }, {
                      default: _withCtx(() => [
                        _createVNode(_component_FormInput, {
                          field: "data",
                          class: "col-span-full"
                        }, {
                          default: _withCtx(({model: inputModel}) => [
                            _createVNode(_component_InputJson, {
                              modelValue: inputModel.value,
                              "onUpdate:modelValue": ($event: any) => ((inputModel.value) = $event),
                              style: {"height":"50svh"},
                              class: "w-full",
                              schema: _ctx.schema
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "schema"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["layout"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Button, _normalizeProps({
                  class: "btn-green btn-lg",
                  loading: _ctx.onSubmit.running.value,
                  [(_ctx.$test.action) || ""]: 'submit',
                  disabled: !isValid,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.submit()))
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_PhIcon, {
                      icon: "PhCheck",
                      size: '2xl'
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('action.save')), 1)
                  ]),
                  _: 2
                }, 1040, ["loading", "disabled"]),
                _createVNode(_component_Button, _normalizeProps({
                  class: "btn-outline-green btn-lg",
                  [(_ctx.$test.action) || ""]: 'close',
                  onClick: _ctx.onClose
                }), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('action.close')), 1)
                  ]),
                  _: 1
                }, 16, ["onClick"])
              ])
            ]),
            _: 1
          }, 16, ["onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}