import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeProps as _normalizeProps } from "vue"

const _hoisted_1 = { class: "relative py-3 md:py-4 flex border-b border-light-gray justify-between px-5" }
const _hoisted_2 = { class: "uppercase typo-body-1 text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AsideCollapseToggle = _resolveComponent("AsideCollapseToggle")!
  const _component_AsideMenu = _resolveComponent("AsideMenu")!
  const _component_AsideApplications = _resolveComponent("AsideApplications")!
  const _component_AsideLocale = _resolveComponent("AsideLocale")!
  const _component_AsideUser = _resolveComponent("AsideUser")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps({
    ref: "asideRef",
    class: ["aside group/aside transition-all flex flex-col bg-white z-10", [
      {'aside--collapsed': _ctx.collapsed},
    ]],
    [(_ctx.$test.asideCollapsed) || ""]: _ctx.collapsed,
    onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hovered = true)),
    onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hovered = false))
  }), [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "flex gap-5",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo, { class: "aside-header__logo inline-block" }),
          _createElementVNode("div", {
            class: _normalizeClass(["aside-header__title flex flex-col gap-0.5 flex-grow justify-center items-start", {'lg:hidden': _ctx.collapsed}])
          }, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.appName), 1),
            (_ctx.environment)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["badge", _ctx.environment.classList])
                }, _toDisplayString(_ctx.environment.label) + " - " + _toDisplayString(_ctx.environment.version), 3))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 1
      }),
      (!_ctx.collapsed)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "lg:hidden text-gray-600",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PhIcon, {
                icon: "PhX",
                class: "h-8 w-8"
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            class: "lg:hidden text-darkest-gray",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PhIcon, {
                icon: "PhList",
                class: "h-8 w-8"
              })
            ]),
            _: 1
          })),
      _createVNode(_component_AsideCollapseToggle, _normalizeProps({
        [(_ctx.$test.action) || ""]: 'collapse-aside',
        class: ["hidden lg:block opacity-0 group-hover/aside:opacity-100 transition duration-200", {'aside-collapsed': _ctx.collapsed}]
      }), null, 16, ["class"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex-grow overflow-y-auto transition", {'hidden lg:block': _ctx.collapsed}])
    }, [
      _createVNode(_component_AsideMenu)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass({'hidden lg:block': _ctx.collapsed})
    }, [
      _createVNode(_component_AsideApplications),
      _createVNode(_component_AsideLocale),
      _createVNode(_component_AsideUser)
    ], 2)
  ], 16))
}