/**
 * NOTE!
 * Functions below are ripped from  api-bundle and vue-grid
 * maybe fixed them and return them to respective repos!
 */

import {
  ActionLike,
  FetchFunctionArgs,
  FilterOutput,
  WithFiltersOptions,
} from '@designeo/apibundle-js/src/Tools';
import {mergeWithUrlSearchParams} from '@designeo/apibundle-js/src/Tools/helpers';
import {formatPaginator} from '@designeo/apibundle-js/src/Tools/paginator';
import {formatSort} from '@designeo/apibundle-js/src/Tools/sort';
import {Filter, Filters} from '@designeo/apibundle-js/src/Types/grid';
import {preventRace} from '@ui/helpers/promise';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {
  map,
  omit,
  reduce,
} from 'lodash-es';
import {DateTime} from 'luxon';
import {computed, ref} from 'vue';
import {emitTestEvent} from '../testEvent';

enum OPERATIONS {
  like = 'valuesLike',
  equal = 'valueEqual',
  notEqual = 'valueNotEqual',
  greaterOrEqual = 'valueGreaterOrEqual',
  lowerOrEqual = 'valueLowerOrEqual',
  in = 'valuesEqual',
  null = 'valueNull',
  notNull ='valueNotNull',
}

enum NUMERIC_OPERATIONS {
  equal = 0,
  notEqual = 1,
  greaterOrEqual = 2,
  lowerOrEqual =3,
}

enum DATERANGE_OPERATIONS {
  greaterOrEqual = 'from',
  lowerOrEqual = 'to',
}

function fromDate(date: Date|string) {
  if (typeof date !== 'undefined' && !isNaN(+new Date(date))) {
    return new Date(date);
  } else {
    return null;
  }
}

function formatDate(value: Date|string, {shift = null}: {shift?: 'startOfDay' | 'endOfDay'} = {}) {
  let date = fromDate(value);

  if (!date) {
    return null;
  }

  if (shift) {
    if (shift === 'startOfDay') {
      date = DateTime
        .fromJSDate(date)
        .startOf('day')
        .toJSDate();
    } else if (shift === 'endOfDay') {
      date = DateTime
        .fromJSDate(date)
        .endOf('day')
        .toJSDate();
    }
  }

  return date
    .toISOString()
    .replace(/(\.[0-9]{3})/, '') ?? null;
}

export function formatFilters(filters: Filters) {
  const params = new URLSearchParams();

  for (const rawFilter of Object.values(filters)) {
    const filter: Filter = {
      name: rawFilter.name.split('@')[0],
      operation: rawFilter.operation,
      value: rawFilter.value,
      type: rawFilter.type,
    };

    if (filter.value === null || filter.value === undefined) {
      continue;
    }

    if (filter.type === 'string') {
      params.append(`fields[${filter.name}][o]`, `${OPERATIONS[filter.operation] ?? filter.operation}`);

      if (filter.operation === 'in') {
        for (const value of [].concat(filter.value)) {
          params.append(`fields[${filter.name}][v][]`, `${value}`);
        }
      } else {
        params.append(`fields[${filter.name}][v]`, `${filter.value}`);
      }
    } else if (filter.type === 'number') {
      if (filter.operation === 'in') {
        params.append(`fields[${filter.name}][o]`, 'valuesEqual');

        for (const value of [].concat(filter.value)) {
          params.append(`fields[${filter.name}][v][]`, `${value}`);
        }
      } else {
        params.append(`fields[${filter.name}][o]`, 'valueNumeric');
        for (const value of [].concat(filter.value)) {
          params.append(`fields[${filter.name}][v][]`, `${value}`);
        }
        params.append(`fields[${filter.name}][v][c]`, `${NUMERIC_OPERATIONS[filter.operation] ?? filter.operation}`);
      }
    } else if (filter.type === 'date') {
      params.append(`fields[${filter.name}][o]`, 'valueDateRange');

      // @ts-ignore TODO enhance enum
      if (filter.operation === 'equalDay') {
        params.append(
          `fields[${filter.name}][v][${DATERANGE_OPERATIONS['greaterOrEqual']}]`,
          `${formatDate(filter.value, {shift: 'startOfDay'})}`,
        );

        params.append(
          `fields[${filter.name}][v][${DATERANGE_OPERATIONS['lowerOrEqual']}]`,
          `${formatDate(filter.value, {shift: 'endOfDay'})}`,
        );
      } else {
        params.append(
          `fields[${filter.name}][v][${DATERANGE_OPERATIONS[filter.operation] ?? filter.operation}]`,
          `${formatDate(filter.value)}`,
        );
      }
    } else if (filter.type === 'dateRange') {
      params.append(`fields[${filter.name}][o]`, 'valueDateRange');

      params.append(
        `fields[${filter.name}][v][${DATERANGE_OPERATIONS.greaterOrEqual}]` ?? filter.operation,
        `${formatDate(filter.value[0])}`,
      );
      params.append(
        `fields[${filter.name}][v][${DATERANGE_OPERATIONS.lowerOrEqual}]` ?? filter.operation,
        `${formatDate(filter.value[1])}`,
      );
    } else if (filter.type === 'boolean') {
      // For some reason filter values get stringified :/
      if (filter.value === 'null' || filter.value === '') {
        params.delete(`fields[${filter.name}][o]`);
        return;
      }

      params.append(`fields[${filter.name}][o]`, `${OPERATIONS[filter.operation] ?? filter.operation}`);

      if (filter.operation === 'in') {
        for (const value of [].concat(filter.value)) {
          params.append(`fields[${filter.name}][v][]`, `${value ? 'true' : 'false'}`);
        }
      } else {
        params.append(`fields[${filter.name}][v]`, `${filter.value ? 'true' : 'false'}`);
      }
    }
  }

  return params;
}

export function createGridState({filters, sort, paginator}) {
  return {
    filters: Object.keys(filters),
    sort: map(sort, ({key, direction}) => `${key}:${direction}`),
    paginator: {
      total: paginator.total,
      offset: paginator.offset,
      pageSize: paginator.pageSize,
    },
  };
}

function emitGridTestEvent(filters, sort, paginator) {
  emitTestEvent(TestEvent.gridFetched, createGridState({filters, sort, paginator}));
}

function extractFilter (
  modelFilters: FetchFunctionArgs['filters'],
  extractFilters: Array<{filterName: string, paramName: string}>,
) {
  let sanitizedFilters = modelFilters;

  const extractedFilters: {[key: string]: FetchFunctionArgs['filters'][string]} = reduce(
    extractFilters,
    (acc, {filterName, paramName}) => {
      if (!modelFilters[filterName]) {
        return acc;
      }

      acc[paramName] = modelFilters[filterName];
      sanitizedFilters = omit(modelFilters, filterName);

      return acc;
    },
    {},
  );

  return {
    sanitizedFilters,
    extractedFilters,
  };
}

export function withFilters<A, T extends ActionLike<A>>(
  fn: T,
  {
    filtersAsSimpleParam = [],
    unwrap = false,
    params = {},
  }: WithFiltersOptions & {filtersAsSimpleParam?: Array<{filterName: string, paramName: string}>} = {},
) {
  const fetchTries = ref(0);
  const fnWithPreventedRace = preventRace(fn);

  const wrappedFn = async ({
    filters, sort, paginator,
  }: {filters: {[key: string]: any}, sort: any[], paginator: any}): Promise<FilterOutput<A>> => {
    const fetchParams = {
      ...params,
    };

    const {
      extractedFilters,
      sanitizedFilters,
    } = extractFilter(filters, filtersAsSimpleParam);

    for (const extractedFilterKey of Object.keys(extractedFilters)) {
      const extractedFilter = extractedFilters[extractedFilterKey];

      fetchParams[extractedFilterKey] = extractedFilter.value;
    }

    try {
      const result = await fnWithPreventedRace({
        params: mergeWithUrlSearchParams(
          formatPaginator(paginator),
          formatSort(sort),
          formatFilters(sanitizedFilters),
          fetchParams,
        ),
      });

      emitGridTestEvent(filters, sort, {
        offset: paginator.offset,
        pageSize: result.limit,
        total: result.total,
      });

      const data = unwrap ? map(result.data, 'data') : result.data;

      return {
        data,
        paginator: {
          offset: paginator.offset,
          pageSize: result.limit,
          total: result.total,
        },
      };
    } catch (e) {
      emitTestEvent(TestEvent.error, e);

      throw e;
    } finally {
      fetchTries.value = fetchTries.value + 1;
    }
  };


  Object.defineProperty(wrappedFn, 'createFetchCounter', {
    value: () => computed(() => fetchTries.value),
  });

  return wrappedFn;
}

export function wrapWithGridFilters(gridModel: {filters: {[key: string]: any}}, {
  filtersAsSimpleParam = [],
} = {}) {
  return (fetchParams: Parameters<typeof mergeWithUrlSearchParams>[number] = {}) => {
    const {
      extractedFilters,
      sanitizedFilters,
    } = extractFilter(gridModel.filters, filtersAsSimpleParam);

    for (const extractedFilterKey of Object.keys(extractedFilters)) {
      const extractedFilter = extractedFilters[extractedFilterKey];

      fetchParams[extractedFilterKey] = extractedFilter.value;
    }


    return mergeWithUrlSearchParams(
      formatFilters(sanitizedFilters),
      fetchParams,
    );
  };
}
