

import Button from '@ui/components/button/Button.vue';
import Form from '@ui/components/form/Form.vue';
import FormContainer from '@ui/components/form/FormContainer.vue';
import FormContainerSection from '@ui/components/form/FormContainerSection.vue';
import FormInput from '@ui/components/form/FormInput.vue';
import InputJson from '@ui/components/input/InputJson.vue';
import Modal from '@ui/components/modal/Modal.vue';
import PhIcon from '@ui/components/PhIcon.vue';
import {useAsyncWithInteractions} from '@ui/helpers/async';
import {FormContainerSectionLayout, useForm} from '@ui/helpers/form';
import {getFeatureValidations} from '@ui/modules/Core/Feature/validations';
import {useFeatureStore} from '@ui/modules/Core/store/FeatureStore';
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'ModalFeature',
  components: {
    FormInput,
    InputJson,
    PhIcon,
    Button,
    Form,
    Modal,
    FormContainer,
    FormContainerSection,
  },
  inheritAttrs: false,
  setup() {
    const featureStore = useFeatureStore();

    const form = useForm({
      entity: () => featureStore.formEntity.value,
      validations: getFeatureValidations({
        getJsonSchema: () => JSON.stringify(featureStore.formEntity.value.schema),
      }),
    });

    const schema = computed(() => {
      return featureStore.formEntity.value.schema;
    });

    const onSubmit = useAsyncWithInteractions(async () => {
      await featureStore.submitFormEntity();
    });

    const onClose = () => {
      featureStore.closeForm();
    };

    const isOpen = computed(() => {
      return featureStore.formEntity.value !== null;
    });

    const title = computed(() => {
      return 'Features';
    });

    const formContainerSectionLayout = FormContainerSectionLayout.single;

    return {
      isOpen,
      title,
      form,
      schema,
      onSubmit,
      onClose,
      formContainerSectionLayout,
    };
  },
});
