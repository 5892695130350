
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ModalDetachedButtons',
  props: {
    single: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
