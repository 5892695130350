export default {
  cs: {
    pointOfSaleTransaction: {
      modalTransactionDetail: {
        title: 'Detail transakce',
        tabs: {
          financialCloseDay: {
            title: 'Finanční uzávěrka',
          },
          journal: {
            title: 'Pokladní deník',
            fetchError: 'Nepodařilo se načíst data',
            loading: 'Načítaní dat',
          },
        },
      },
      fields: {
        documentDate: 'Datum a čas',
        documentType: 'Typ',
        cashier: 'Uživatel',
        isSapProcessed: 'SAP',
      },
    },
  },
  sk: {
    pointOfSaleTransaction: {
      modalTransactionDetail: {
        title: 'Detail transakcie',
        tabs: {
          financialCloseDay: {
            title: 'Finančné uzávierky',
          },
          journal: {
            title: 'Pokladničný denník',
            fetchError: 'Nepodarilo sa načítať dáta',
            loading: 'Načítanie dát',
          },
        },
      },
      fields: {
        documentDate: 'Dátum a čas',
        documentType: 'Typ',
        cashier: 'Používateľ',
        isSapProcessed: 'SAP',
      },
    },
  },
  en: {
    pointOfSaleTransaction: {
      modalTransactionDetail: {
        title: 'Transaction detail',
        tabs: {
          financialCloseDay: {
            title: 'Financial close day',
          },
          journal: {
            title: 'Journal',
            fetchError: 'Failed to load data',
            loading: 'Loading data',
          },
        },
      },
      fields: {
        documentDate: 'Date and time',
        documentType: 'Type',
        cashier: 'User',
        isSapProcessed: 'SAP',
      },
    },
  },
  de: {
    pointOfSaleTransaction: {
      modalTransactionDetail: {
        title: 'Transaktionsdetails',
        tabs: {
          financialCloseDay: {
            title: 'Finanzabschluss',
          },
          journal: {
            title: 'Kassenbuch',
            fetchError: 'Daten konnten nicht abgerufen werden',
            loading: 'Daten werden geladen',
          },
        },
      },
      fields: {
        documentDate: 'Datum und Uhrzeit',
        documentType: 'Typ',
        cashier: 'Benutzer',
        isSapProcessed: 'SAP',
      },
    },
  },
};
