export function registerSSRState(ssrState = undefined) {
  return function() {
    return {
      ssrState: {
        // undefined if on server or spa
        ssrState,
        serialize() {
          // TODO: get somehow all ssr data and return it in json
          return {
            foo: 'bar',
          };
        },
      },
    };
  };
}
