import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/cashier-roles',
    name: 'cashier-roles',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.cashierRoleList,
    },
  },
  {
    path: '/cashier-role/:id',
    name: 'cashier-role',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
  },
  {
    path: '/cashier-role/update/:id',
    name: 'cashier-role-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/cashier-role/create',
    name: 'cashier-role-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.cashierRoleCreate,
    },
  },
] as RegisterRoutesOptions;
