import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/voucher-emissions',
    name: 'voucher-emissions',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.voucherEmissionList,
    },
  },
  {
    path: '/voucher-emission/:id',
    name: 'voucher-emission',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'voucher-emission-vouchers'},
    children: [
      {
        path: 'vouchers',
        name: 'voucher-emission-vouchers',
        component: () => import('./Detail/tabs/TabVouchers.vue'),
      },
    ],
  },
  {
    path: '/voucher-emission/update/:id',
    name: 'voucher-emission-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/voucher-emission/create',
    name: 'voucher-emission-create',
    component: () => import('./Form/CreatePage.vue'),
  },
] as RegisterRoutesOptions;
