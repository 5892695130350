import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HLField = _resolveComponent("HLField")!

  return (_openBlock(), _createBlock(_component_HLField, { field: _ctx.field }, {
    default: _withCtx((slotProps) => [
      _createElementVNode("div", _mergeProps({ class: "flex flex-col gap-3 w-full" }, _ctx.$attrs), [
        (_ctx.$slots.title)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex flex-row gap-0.5 typo-body-3 text-darkest-gray", {
          'cursor-not-allowed': _ctx.disabled || _ctx.readonly,
          'italic': _ctx.readonly
        }])
            }, [
              _renderSlot(_ctx.$slots, "title"),
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
          ...slotProps,
          id: _ctx.field,
        }))),
        (_ctx.displayErrors)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(slotProps.errors, (error, index) => {
              return (_openBlock(), _createElementBlock("div", _normalizeProps({
                key: index,
                class: "mt-1 text-sm text-red",
                [(_ctx.$test.formInputError) || ""]: _ctx.field
              }), [
                (_ctx.isTypeJson(error))
                  ? (_openBlock(), _createElementBlock("pre", _hoisted_2, _toDisplayString(_ctx.stringifyFormat(error, null, 2)), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(error), 1))
              ], 16))
            }), 128))
          : _createCommentVNode("", true)
      ], 16)
    ]),
    _: 3
  }, 8, ["field"]))
}