import {VoucherEventOperation} from '@api/posMiddlewareCore/constants/voucherEventOperation';

export default {
  cs: {
    voucherEvent: {
      fields: {
        createdDate: 'Datum',
        operation: 'Akce',
        operationEnum: {
          [VoucherEventOperation.creation]: 'Vygenerování poukázky',
          [VoucherEventOperation.cancellation]: 'Storno poukázky',
          [VoucherEventOperation.redemption]: 'Uplatnění poukázky',
          [VoucherEventOperation.manualChange]: 'Manuální změna',
        },
        place: 'Místo',
        customer: 'Zákazník',
        customerNumber: 'Přiřazeno k zákazníkovi',
        cashier: 'Pokladní',
        updatedByName: 'Uživatel',
        state: 'Stav',
        usageCount: 'Počet použití',
      },
    },
  },
  sk: {
    voucherEvent: {
      fields: {
        createdDate: 'Dátum',
        operation: 'Akcia',
        operationEnum: {
          [VoucherEventOperation.creation]: 'Vytvorenie poukážky',
          [VoucherEventOperation.cancellation]: 'Storno poukážky',
          [VoucherEventOperation.redemption]: 'Uplatnenie poukážky',
          [VoucherEventOperation.manualChange]: 'Manuálna zmena',
        },
        place: 'Miesto',
        customer: 'Zákazník',
        customerNumber: 'Priradené zákazníkovi',
        cashier: 'Pokladník',
        updatedByName: 'Používateľ',
        state: 'Stav',
        usageCount: 'Počet použití',
      },
    },
  },
  en: {
    voucherEvent: {
      fields: {
        createdDate: 'Date',
        operation: 'Operation',
        operationEnum: {
          [VoucherEventOperation.creation]: 'Voucher generation',
          [VoucherEventOperation.cancellation]: 'Voucher cancellation',
          [VoucherEventOperation.redemption]: 'Voucher redemption',
          [VoucherEventOperation.manualChange]: 'Manual change',
        },
        place: 'Place',
        customer: 'Customer',
        customerNumber: 'Assigned to customer',
        cashier: 'Cashier',
        updatedByName: 'User',
        state: 'State',
        usageCount: 'Usage count',
      },
    },
  },
  de: {
    voucherEvent: {
      fields: {
        createdDate: 'Datum',
        operation: 'Aktion',
        operationEnum: {
          [VoucherEventOperation.creation]: 'Erstellung des Gutscheins',
          [VoucherEventOperation.cancellation]: 'Stornierung des Gutscheins',
          [VoucherEventOperation.redemption]: 'Einlösung des Gutscheins',
          [VoucherEventOperation.manualChange]: 'Manuelle Änderung',
        },
        place: 'Ort',
        customer: 'Kunde',
        customerNumber: 'Zugeordnet zum Kunden',
        cashier: 'Kassierer',
        updatedByName: 'Benutzer',
        state: 'Status',
        usageCount: 'Anzahl der Verwendungen',
      },
    },
  },
};

