export const localeToNationFlag = (locale) => {
  switch (locale) {
    case 'cs':
      return 'cz';
    case 'en':
      return 'gb';
    case 'sk':
      return 'sk';
    case 'de':
      return 'de';
  }
};
