
import {HLForm} from '@designeo/vue-forms';
import {HLPropWatcher} from '@ui/components/headless/Watcher';
import {
  flatten,
  flow,
  isEmpty,
  values,
} from 'lodash-es';
import {
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import * as yup from 'yup';

export default defineComponent({
  components: {
    HLPropWatcher,
    HLForm,
  },
  inheritAttrs: false,
  props: {
    editEntity: {
      type: null,
      required: true,
      default: () => ({}),
    },
    entityDraft: {
      type: null,
      required: false,
      default: () => ({}),
    },
    onSubmit: {
      type: Function as PropType<(entity: any) => void>,
      required: false,
      default: () => {},
    },
    rulesSchema: {
      type: null,
      required: false,
      default: () => yup.object(),
    },
    errors: {
      type: Object,
      default: null,
    },
    useClone: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    noSubmitByEnter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:entityDraft', 'update:errors', 'validate'],
  setup(props) {
    const entity = ref(props.editEntity);
    const formRef = ref();

    watch(() => props.editEntity, (value) => {
      entity.value = value;
    });

    const submit = async (entity: any) => {
      await props.onSubmit(entity);
    };

    const isFormValid = (errors: {[key: string]: any}) => {
      return flow([
        (val) => values(val),
        (val) => flatten(val),
        (val) => isEmpty(val),
      ])(errors);
    };

    return {
      entity,
      submit,
      formRef,
      isFormValid,
    };
  },
});
