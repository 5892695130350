import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeProps as _normalizeProps, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_HLDropdown = _resolveComponent("HLDropdown")!

  return (_openBlock(), _createBlock(_component_HLDropdown, { placement: "right-end" }, {
    default: _withCtx(({
      triggerRef,
      dropdownRef,
      isActive,
      toggle,
    }) => [
      _createElementVNode("div", _mergeProps(_ctx.$attrs, {
        ref: (el) => triggerRef.value = el,
        class: {
        'justify-center': _ctx.collapsed
      }
      }), [
        _createElementVNode("div", _normalizeProps({
          [(_ctx.$test.asideLocale) || ""]: _ctx.collapsed,
          class: "text-center typo-body-1 text-gray-600 tracking-wide font-medium uppercase py-4 px-5 flex flex-row justify-between items-center cursor-pointer",
          onClick: toggle
        }), [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row flex-grow items-center gap-4", {
            'justify-center': _ctx.collapsed
          }])
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_PhIcon, {
                icon: "PhTranslate",
                size: '5xl'
              })
            ]),
            (!_ctx.collapsed && !_ctx.collapsedAfterAnimation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('layout.components.aside.menu.changeLocale')), 1))
              : _createCommentVNode("", true)
          ], 2),
          (!_ctx.collapsed)
            ? (_openBlock(), _createBlock(_component_PhIcon, {
                key: 0,
                icon: "PhCaretDown",
                size: "lg",
                class: _normalizeClass(["transform transition-all justify-self-end", {
            '-rotate-180 lg:-rotate-90': isActive.value
          }])
              }, null, 8, ["class"]))
            : _createCommentVNode("", true)
        ], 16, _hoisted_1),
        (isActive.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: (el) => dropdownRef.value = el,
              class: "lg:absolute bg-white rounded border border-gray-200 py-6 px-6 text-darkest-gray",
              style: {"min-width":"250px"}
            }, [
              _createElementVNode("div", _normalizeProps({
                class: "flex flex-col gap-8",
                [(_ctx.$test.currentLocale) || ""]: _ctx.currentLocale
              }), [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLocales, (locale, index) => {
                  return (_openBlock(), _createElementBlock("button", _normalizeProps({
                    key: index,
                    [(_ctx.$test.changeLocale) || ""]: locale,
                    class: "flex flex-row items-center gap-7 cursor-pointer",
                    onClick: ($event: any) => (_ctx.onChangeLocale(locale))
                  }), [
                    _createElementVNode("div", {
                      class: _normalizeClass([[`nation-flag--${_ctx.localeToNationFlag(locale)}`], "w-6 h-6"])
                    }, null, 2),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", {
                        class: _normalizeClass([locale === _ctx.currentLocale ? 'typo-body-1 underline' : 'typo-body-2'])
                      }, _toDisplayString(_ctx.$t(`locales.${locale}`)), 3)
                    ])
                  ], 16, _hoisted_3))
                }), 128))
              ], 16)
            ], 512))
          : _createCommentVNode("", true)
      ], 16)
    ]),
    _: 1
  }))
}