
import {HLDropdown} from '@ui/components/headless/Dropdown';
import PhIcon from '@ui/components/PhIcon.vue';
import {injectAsideContext} from '@ui/helpers/aside';
import {localeToNationFlag} from '@ui/helpers/localeFlags';
import {i18nLocaleLocalStorage} from '@ui/helpers/persist';
import {defineComponent} from 'vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'AsideLocale',
  components: {
    HLDropdown,
    PhIcon,
  },
  setup() {
    const {availableLocales, locale: currentLocale} = useI18n();

    const {collapsed, collapsedAfterAnimation} = injectAsideContext();

    const onChangeLocale = (locale: string) => {
      i18nLocaleLocalStorage.set(locale);
      currentLocale.value = locale;
    };

    return {
      availableLocales,
      currentLocale,
      collapsed,
      collapsedAfterAnimation,
      localeToNationFlag,
      onChangeLocale,
    };
  },
});
