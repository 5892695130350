import StringType from '@api/posMiddlewareCore/types/StringType';
import {createEntity} from '@designeo/apibundle-js';
import {Feature} from '@middleware-ui/constants/feature';


export class FeatureFormEntity extends createEntity({
  data: {
    type: new StringType(),
  },
}) {
  get schema() {
    return {
      '$schema': 'http://json-schema.org/draft-07/schema',
      'version': '0.0.1',
      'type': 'array',
      'items': {
        'type': 'object',
        'uniqueItems': true,
        'discriminator': {
          'propertyName': 'name',
        },
        'required': [
          'name',
        ],
        'oneOf': [
          {
            'type': 'object',
            'properties': {
              'name': {
                'const': Feature.livePromotionDebugger,
              },
              'parameters': {
                'type': 'object',
                'additionalProperties': false,
                'properties': {
                  'posDebugUrl': {
                    'type': 'string',
                  },
                },
                'required': [
                  'posDebugUrl',
                ],
              },
            },
            'required': [
              'name',
              'parameters',
            ],
          }, {
            'type': 'object',
            'properties': {
              'name': {
                'const': Feature.configurationEditor,
              },
            },
          },
        ],
      },
    };
  }
  get app() {
    return 'middleware-ui';
  }
}
