import {PromotionState} from '@api/posMiddlewareCore/constants/promotionState';

export default {
  cs: {
    promotion: {
      detail: {
        title: 'Promoce',
      },
      grid: {
        title: 'Promoce',
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
          isValid: 'Platnost',
          state: 'Stav',
        },
        advancedFilters: {
          title: 'Filtrovat promoce',
          search: 'Vyhledat',
          placeholders: {
            search: 'Zadejte název (min. 2 znaky)',
            isValid: 'Vyberte platnost',
            state: 'Vyberte stav',
          },
        },
        actions: {
          create: {
            title: 'Založit promoci',
          },
          bulkStateChange: {
            title: 'Změna stavu',
          },
          bulkDelete: {
            title: 'Mazání',
            confirm: 'Opravdu si přejete hromadně změnit stav?',
          },
          bulkExpiration: {
            title: 'Změna platnosti (statická)',
          },
          bulkExpirationRelative: {
            title: 'Změna platnosti (relativní)',
          },
          exportAndImport: {
            title: 'Export a import',
          },
          export: {
            title: 'Export',
          },
        },
      },
      form: {
        create: {
          title: 'Nová promoce',
        },
        update: {
          title: 'Editace promoce',
        },
        bulkStateChange: {
          title: 'Hromadná změna stavu',
        },
        bulkExpiration: {
          title: 'Hromadná změna platnosti (statická)',
        },
        bulkExpirationRelative: {
          title: 'Hromadná změna platnosti (relativní)',
        },
        transferDate: {
          fillValidFrom: 'Posunout platnost od',
          fillValidTill: 'Posunout platnost do',
        },
        saveGlobalTemplate: {
          success: 'Globální šablona byla uložena',
        },
        deleteGlobalTemplate: {
          success: 'Globální šablona byla smazána',
        },
        placeholders: {
          name: 'Zadejte název',
          state: 'Vyberte stav',
          addDays: 'Zadejte počet dní',
        },
        validations: {
          anyTransfer: 'Posun je povinný',
        },
      },
      fields: {
        name: 'Název',
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        fillValidFrom: 'Posunout platnost od',
        fillValidTill: 'Posunout platnost do',
        addDays: 'Relativně o dny (lze zadat se znaménkem mínus)',
        state: 'Stav',
        stateEnum: {
          [PromotionState.draft]: 'Koncept',
          [PromotionState.activeForTest]: 'Aktivováno pro TEST',
          [PromotionState.activeForProd]: 'Aktivováno pro PROD',
          [PromotionState.archived]: 'Archivováno',
        },
        isActive: 'Platnost',
        isActiveEnum: {
          true: 'Platné',
          false: 'Neplatné',
        },
        editedByName: 'Upraveno',
        editedDate: 'Datum editace',
        edited: 'Upraveno',
        promotionScript: 'Definice promoce',
      },
      modalExportImport: {
        title: 'Promoce - Export a import',
        import: {
          title: 'Vymazat a importovat',
          description: 'Vyberte soubor ve formátu JSON, který obsahuje definice promocí a promočních košů.',
          warning: 'Všechny aktuální promoce a promoční koše budou vymazány a dojde k jejich nahrazení daty ze souboru.',
        },

        export: {
          title: '@:action.export',
          promotionAndPromotionLists: 'Promoce a promoční koše',
          description: 'Export všech promocí a promočních košů ve formátu JSON.',
        },
      },
    },
    promotions: {
      errors: {
        articleGroupDoesntExists: 'Promoční koš "{name}" neexistuje',
        internalNumberDoesntExists: 'Interní číslo "{internalNumber}" neexistuje',
        promoIdEndsWithDotAndNumber: 'Promo "{name}" končí tečkou a číslem',
        nProblemsFound: 'Nalezeno {n} problémů  | Nalezen {n} problém | Nalezeny {n} problémy | Nalezeno {n} problémů',
      },
    },
  },
  sk: {
    promotion: {
      detail: {
        title: 'Promócie',
      },
      grid: {
        title: 'Promócie',
        filters: {
          search: 'Vyhľadať (min. 2 znaky)',
          code: 'Kód',
          isValid: 'Platnosť',
          state: 'Stav',
        },
        advancedFilters: {
          title: 'Filtrovať promoce',
          search: 'Vyhľadať',
          placeholders: {
            search: 'Zadajte názov (min. 2 znaky)',
            isValid: 'Vyberte platnosť',
            state: 'Vyberte stav',
          },
        },
        actions: {
          create: {
            title: 'Založiť promóciu',
          },
          bulkStateChange: {
            title: 'Zmena stavu',
          },
          bulkDelete: {
            title: 'Mazanie',
            confirm: 'Naozaj si želáte hromadne zmeniť stav?',
          },
          bulkExpiration: {
            title: 'Zmena platnosti (statická)',
          },
          bulkExpirationRelative: {
            title: 'Zmena platnosti (relatívna)',
          },
          exportAndImport: {
            title: 'Export a import',
          },
          export: {
            title: 'Export',
          },
        },
      },
      form: {
        create: {
          title: 'Nová promočia',
        },
        update: {
          title: 'Editácia promočie',
        },
        bulkStateChange: {
          title: 'Hromadná zmena stavu',
        },
        bulkExpiration: {
          title: 'Hromadná zmena platnosti (statická)',
        },
        bulkExpirationRelative: {
          title: 'Hromadná zmena platnosti (relatívna)',
        },
        transferDate: {
          fillValidFrom: 'Posunúť platnosť od',
          fillValidTill: 'Posunúť platnosť do',
        },
        saveGlobalTemplate: {
          success: 'Globálna šablóna bola uložená',
        },
        deleteGlobalTemplate: {
          success: 'Globálna šablóna bola zmazaná',
        },
        placeholders: {
          name: 'Zadajte názov',
          state: 'Vyberte stav',
          addDays: 'Zadajte počet dní',
        },
        validations: {
          anyTransfer: 'Posun je povinný',
        },
      },
      fields: {
        name: 'Názov',
        validFrom: 'Platnosť od',
        validTill: 'Platnosť do',
        fillValidFrom: 'Posunúť platnosť od',
        fillValidTill: 'Posunúť platnosť do',
        addDays: 'Relatívne o dni (možné zadať so znamienkom mínus)',
        state: 'Stav',
        stateEnum: {
          [PromotionState.draft]: 'Koncept',
          [PromotionState.activeForTest]: 'Aktivované pre TEST',
          [PromotionState.activeForProd]: 'Aktivované pre PROD',
          [PromotionState.archived]: 'Archivované',
        },
        isActive: 'Platnosť',
        isActiveEnum: {
          true: 'Platné',
          false: 'Neplatné',
        },
        editedByName: 'Upravené',
        editedDate: 'Dátum editácie',
        edited: 'Upravené',
        promotionScript: 'Definícia promočie',
      },
      modalExportImport: {
        title: 'Promócie - Export a import',
        import: {
          title: 'Vymazať a importovať',
          description: 'Vyberte súbor vo formáte JSON, ktorý obsahuje definície promočí a promočných košíkov.',
          warning: 'Všetky aktuálne promočie a promočné koše budú vymazané a dojde k ich nahradeniu dátami zo súboru.',
        },
        export: {
          title: 'Exportovať',
          promotionAndPromotionLists: 'Promócie a promočné koše',
          description: 'Export všetkých promočí a promočných košíkov vo formáte JSON.',
        },
      },
    },
    promotions: {
      errors: {
        articleGroupDoesntExists: 'Promočný koš "{name}" neexistuje',
        internalNumberDoesntExists: 'Interné číslo "{internalNumber}" neexistuje',
        promoIdEndsWithDotAndNumber: 'Promo "{name}" končí bodkou a číslom',
        nProblemsFound: 'Nájdeno {n} problémov  | Nájdený {n} problém | Nájdené {n} problémy | Nájdeno {n} problémov',
      },
    },
  },
  en: {
    promotion: {
      detail: {
        title: 'Promotions',
      },
      grid: {
        title: 'Promotions',
        filters: {
          search: 'Search (min. 2 characters)',
          code: 'Code',
          isValid: 'Validity',
          state: 'State',
        },
        advancedFilters: {
          title: 'Filter promotions',
          search: 'Search',
          placeholders: {
            search: 'Enter name (min. 2 characters)',
            isValid: 'Select validity',
            state: 'Select state',
          },
        },
        actions: {
          create: {
            title: 'Create promotion',
          },
          bulkStateChange: {
            title: 'Change state',
          },
          bulkDelete: {
            title: 'Delete',
            confirm: 'Are you sure you want to change state in bulk?',
          },
          bulkExpiration: {
            title: 'Change validity (static)',
          },
          bulkExpirationRelative: {
            title: 'Change validity (relative)',
          },
          exportAndImport: {
            title: 'Export and import',
          },
          export: {
            title: 'Export',
          },
        },
      },
      form: {
        create: {
          title: 'New promotion',
        },
        update: {
          title: 'Edit promotion',
        },
        bulkStateChange: {
          title: 'Bulk state change',
        },
        bulkExpiration: {
          title: 'Bulk validity change (static)',
        },
        bulkExpirationRelative: {
          title: 'Bulk validity change (relative)',
        },
        transferDate: {
          fillValidFrom: 'Fill validity from',
          fillValidTill: 'Fill validity till',
        },
        saveGlobalTemplate: {
          success: 'Global template was saved',
        },
        deleteGlobalTemplate: {
          success: 'Global template was deleted',
        },
        placeholders: {
          name: 'Enter name',
          state: 'Select state',
          addDays: 'Enter number of days',
        },
        validations: {
          anyTransfer: 'Transfer is required',
        },
      },
      fields: {
        name: 'Name',
        validFrom: 'Valid from',
        validTill: 'Valid till',
        fillValidFrom: 'Fill validity from',
        fillValidTill: 'Fill validity till',
        addDays: 'Relative days (can be negative)',
        state: 'State',
        stateEnum: {
          [PromotionState.draft]: 'Draft',
          [PromotionState.activeForTest]: 'Active for TEST',
          [PromotionState.activeForProd]: 'Active for PROD',
          [PromotionState.archived]: 'Archived',
        },
        isActive: 'Validity',
        isActiveEnum: {
          true: 'Valid',
          false: 'Invalid',
        },
        editedByName: 'Edited',
        editedDate: 'Edited date',
        edited: 'Edited',
        promotionScript: 'Promotion definition',
      },
      modalExportImport: {
        title: 'Promotions - Export and import',
        import: {
          title: 'Delete and import',
          description: 'Select JSON file with promotion and promotion list definitions.',
          warning: 'All current promotions and promotion lists will be deleted and replaced by data from the file.',
        },

        export: {
          title: '@:action.export',
          promotionAndPromotionLists: 'Promotions and promotion lists',
          description: 'Export all promotions and promotion lists in JSON format.',
        },
      },
    },
    promotions: {
      errors: {
        articleGroupDoesntExists: 'Promotion list "{name}" does not exist',
        internalNumberDoesntExists: 'Internal number "{internalNumber}" does not exist',
        promoIdEndsWithDotAndNumber: 'Promo "{name}" ends with dot and number',
        nProblemsFound: 'Found {n} problems | Found {n} problem | Found {n} problems | Found {n} problems',
      },
    },
  },
  de: {
    promotion: {
      detail: {
        title: 'Aktionen',
      },
      grid: {
        title: 'Aktionen',
        filters: {
          search: 'Suchen (min. 2 Zeichen)',
          code: 'Code',
          isValid: 'Gültigkeit',
          state: 'Status',
        },
        advancedFilters: {
          title: 'Aktionen filtern',
          search: 'Suchen',
          placeholders: {
            search: 'Geben Sie einen Namen ein (min. 2 Zeichen)',
            isValid: 'Gültigkeit auswählen',
            state: 'Status auswählen',
          },
        },
        actions: {
          create: {
            title: 'Aktion erstellen',
          },
          bulkStateChange: {
            title: 'Status ändern',
          },
          bulkDelete: {
            title: 'Löschen',
            confirm: 'Wollen Sie den Status wirklich in Massen ändern?',
          },
          bulkExpiration: {
            title: 'Gültigkeitsänderung (statisch)',
          },
          bulkExpirationRelative: {
            title: 'Gültigkeitsänderung (relativ)',
          },
          exportAndImport: {
            title: 'Exportieren und importieren',
          },
          export: {
            title: 'Exportieren',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Aktion',
        },
        update: {
          title: 'Aktion bearbeiten',
        },
        bulkStateChange: {
          title: 'Massenstatusänderung',
        },
        bulkExpiration: {
          title: 'Massenlaufzeitänderung (statisch)',
        },
        bulkExpirationRelative: {
          title: 'Massenlaufzeitänderung (relativ)',
        },
        transferDate: {
          fillValidFrom: 'Gültigkeit von',
          fillValidTill: 'Gültigkeit bis',
        },
        saveGlobalTemplate: {
          success: 'Globales Muster wurde gespeichert',
        },
        deleteGlobalTemplate: {
          success: 'Globales Muster wurde gelöscht',
        },
        placeholders: {
          name: 'Namen eingeben',
          state: 'Status auswählen',
          addDays: 'Anzahl der Tage eingeben',
        },
        validations: {
          anyTransfer: 'Übertragung ist erforderlich',
        },
      },
      fields: {
        name: 'Name',
        validFrom: 'Gültig ab',
        validTill: 'Gültig bis',
        fillValidFrom: 'Gültigkeit von',
        fillValidTill: 'Gültigkeit bis',
        addDays: 'Relativ um Tage verschieben (kann mit einem Minuszeichen eingegeben werden)',
        state: 'Status',
        stateEnum: {
          [PromotionState.draft]: 'Entwurf',
          [PromotionState.activeForTest]: 'Aktiv für TEST',
          [PromotionState.activeForProd]: 'Aktiv für PROD',
          [PromotionState.archived]: 'Archiviert',
        },
        isActive: 'Gültigkeit',
        isActiveEnum: {
          true: 'Gültig',
          false: 'Ungültig',
        },
        editedByName: 'Bearbeitet von',
        editedDate: 'Bearbeitungsdatum',
        edited: 'Bearbeitet',
        promotionScript: 'Aktionsskript',
      },
      modalExportImport: {
        title: 'Aktionen - Exportieren und importieren',
        import: {
          title: 'Löschen und importieren',
          description: 'Wählen Sie eine Datei im JSON-Format aus, die Definitionen von Aktionen und Aktionseimern enthält.',
          warning: 'Alle aktuellen Aktionen und Aktionseimer werden gelöscht und durch Daten aus der Datei ersetzt.',
        },
        export: {
          title: 'Exportieren',
          promotionAndPromotionLists: 'Aktionen und Aktionseimer',
          description: 'Exportieren Sie alle Aktionen und Aktionseimer im JSON-Format.',
        },
      },
    },
    promotions: {
      errors: {
        articleGroupDoesntExists: 'Aktionseimer "{name}" existiert nicht',
        internalNumberDoesntExists: 'Interne Nummer "{internalNumber}" existiert nicht',
        promoIdEndsWithDotAndNumber: 'Aktion "{name}" endet mit Punkt und Zahl',
        nProblemsFound: 'Gefundene {n} Probleme  | Gefundenes {n} Problem | Gefundene {n} Probleme | Gefundene {n} Probleme',
      },
    },
  },
};
