import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.configurationList,
    },
  },
  {
    path: '/configuration/:id/version/:versionKey',
    name: 'configuration',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'configuration-preview'},
    children: [
      // {
      //   path: 'assigned-pos',
      //   name: 'configuration-assigned-pos',
      //   component: () => import('./Detail/tabs/TabAssignedPos.vue'),
      // },
      {
        path: 'preview',
        name: 'configuration-preview',
        component: () => import('./Detail/tabs/TabConfigurationPreview.vue'),
      },
    ],
  },
  {
    path: '/configuration/update/:id/version/:versionKey',
    name: 'configuration-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/configuration/create',
    name: 'configuration-create',
    component: () => import('./Form/CreatePage.vue'),
  },
] as RegisterRoutesOptions;
