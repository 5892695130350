
import {injectFormContext} from '@designeo/vue-forms/src/Form/Form';
import {FormContainerSectionLayout} from '@ui/helpers/form';
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

export default defineComponent({
  name: 'FormContainerSection',
  props: {
    layout: {
      type: String as PropType<FormContainerSectionLayout>,
      required: false,
      default: FormContainerSectionLayout.base,
    },
  },
  setup(props) {
    const {
      readonly: formReadonly,
      disabled: formDisabled,
    } = injectFormContext();

    const isLayoutBase = computed(() => {
      return props.layout === FormContainerSectionLayout.base;
    });

    const isLayoutSingle = computed(() => {
      return props.layout === FormContainerSectionLayout.single;
    });

    const isLayoutCompressed = computed(() => {
      return props.layout === FormContainerSectionLayout.compressed;
    });

    const isLayoutDetailed = computed(() => {
      return props.layout === FormContainerSectionLayout.detailed;
    });


    return {
      formReadonly,
      formDisabled,
      isLayoutSingle,
      isLayoutBase,
      isLayoutCompressed,
      isLayoutDetailed,
    };
  },
});
