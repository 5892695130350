export default {
  cs: {
    shop: {
      detail: {
        title: 'Prodejna #{code} - {name} ',
        tabs: {
          configurations: {
            title: 'Konfigurace',
            actions: {
              create: 'Založit konfiguraci',
            },
          },
        },
        actions: {
          showOnMap: 'Zobrazit na mapě',
          activate: {
            confirm: 'Opravdu chcete prodejnu aktivovat?',
          },
          deactivate: {
            confirm: 'Opravdu chcete prodejnu deaktivovat?',
          },
        },
      },
      grid: {
        title: 'Prodejny',
        actions: {
          create: {
            title: 'Založit',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          isActive: 'Aktivní',
          isActiveEnum: {
            allOptions: 'Všechny stavy',
          },
        },
        advancedFilters: {
          title: 'Filtrovat prodejny',
          search: 'Vyhledat',
          state: 'Stav',
          placeholders: {
            search: 'Vyhledat (min. 2 znaky)',
          },
        },
      },
      form: {
        create: {
          title: 'Nová prodejna',
        },
        update: {
          title: 'Úprava prodejny',
        },
        common: {
          sections: {
            detailedInformation: 'Podrobné informace',
            locationInformation: 'Informace o poloze',
            addressBook: 'Adresář',
          },
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód prodejny',
          email: 'Zadejte email',
          phone: 'Zadejte telefonní číslo',
          street: 'Zadejte ulici',
          city: 'Zadejte město',
          zip: 'Zadejte PSČ',
          country: 'Zadejte zemi',
          lat: 'Zadejte zeměpisnou šířku',
          lon: 'Zadejte zeměpisnou délku',
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        sapCode: 'SAP kód prodejny',
        id: 'ID',
        address: 'Adresa',
        empty: 'Neuvedeno',
        email: 'Email',
        phone: 'Telefon',
        isActive: 'Aktivní',
        isActiveEnum: {
          true: 'Aktivní',
          false: 'Neaktivní',
        },
        lat: 'Zeměpisná šířka',
        lon: 'Zeměpisná délka',
        country: 'Země',
        city: 'Město',
        street: 'Ulice',
        zip: 'PSČ',
        createdDate: 'Datum vytvoření',
        editedDate: 'Datum editace',
        groupAddress: 'Informace o poloze',
        createdByName: 'Vytvořil',
        editedByName: 'Upravil',
        groupInfo: 'Podrobné informace',
        createNew: 'Založit prodejnu',
        state: 'Stav',
        messageAddressBooks: 'Adresář',
        edited: 'Upraveno',
        created: 'Vytvořeno',
      },
    },
  },
  sk: {
    shop: {
      detail: {
        title: 'Obchod #{code} - {name} ',
        tabs: {
          configurations: {
            title: 'Konfigurácie',
            actions: {
              create: 'Vytvoriť konfiguráciu',
            },
          },
        },
        actions: {
          showOnMap: 'Zobraziť na mape',
          activate: {
            confirm: 'Naozaj chcete aktivovať obchod?',
          },
          deactivate: {
            confirm: 'Naozaj chcete deaktivovať obchod?',
          },
        },
      },
      grid: {
        title: 'Obchody',
        actions: {
          create: {
            title: 'Vytvoriť',
          },
        },
        filters: {
          search: 'Vyhľadať (min. 2 znaky)',
          isActive: 'Aktívny',
          isActiveEnum: {
            allOptions: 'Všetky stavy',
          },
        },
        advancedFilters: {
          title: 'Filtrovať obchody',
          search: 'Vyhľadať',
          state: 'Stav',
          placeholders: {
            search: 'Vyhľadať (min. 2 znaky)',
          },
        },
      },
      form: {
        create: {
          title: 'Nový obchod',
        },
        update: {
          title: 'Úprava obchodu',
        },
        common: {
          sections: {
            detailedInformation: 'Podrobné informácie',
            locationInformation: 'Informácie o polohe',
            addressBook: 'Adresár',
          },
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód obchodu',
          email: 'Zadajte email',
          phone: 'Zadajte telefónne číslo',
          street: 'Zadajte ulicu',
          city: 'Zadajte mesto',
          zip: 'Zadajte PSČ',
          country: 'Zadajte krajinu',
          lat: 'Zadajte zemepisnú šírku',
          lon: 'Zadajte zemepisnú dĺžku',
        },
      },
      fields: {
        name: 'Názov',
        code: 'Kód',
        sapCode: 'SAP kód obchodu',
        id: 'ID',
        address: 'Adresa',
        empty: 'Neuvedené',
        email: 'Email',
        phone: 'Telefón',
        isActive: 'Aktívny',
        isActiveEnum: {
          true: 'Aktívny',
          false: 'Neaktívny',
        },
        lat: 'Zemepisná šírka',
        lon: 'Zemepisná dĺžka',
        country: 'Krajina',
        city: 'Mesto',
        street: 'Ulica',
        zip: 'PSČ',
        createdDate: 'Dátum vytvorenia',
        editedDate: 'Dátum úpravy',
        groupAddress: 'Informácie o polohe',
        createdByName: 'Vytvoril',
        editedByName: 'Upravil',
        groupInfo: 'Podrobné informácie',
        createNew: 'Vytvoriť obchod',
        state: 'Stav',
        messageAddressBooks: 'Adresár',
        edited: 'Upravené',
        created: 'Vytvorené',
      },
    },
  },
  en: {
    shop: {
      detail: {
        title: 'Shop #{code} - {name} ',
        tabs: {
          configurations: {
            title: 'Configurations',
            actions: {
              create: 'Create configuration',
            },
          },
        },
        actions: {
          showOnMap: 'Show on map',
          activate: {
            confirm: 'Are you sure you want to activate the shop?',
          },
          deactivate: {
            confirm: 'Are you sure you want to deactivate the shop?',
          },
        },
      },
      grid: {
        title: 'Shops',
        actions: {
          create: {
            title: 'Create',
          },
        },
        filters: {
          search: 'Search (min. 2 characters)',
          isActive: 'Active',
          isActiveEnum: {
            allOptions: 'All states',
          },
        },
        advancedFilters: {
          title: 'Filter shops',
          search: 'Search',
          state: 'State',
          placeholders: {
            search: 'Search (min. 2 characters)',
          },
        },
      },
      form: {
        create: {
          title: 'New shop',
        },
        update: {
          title: 'Edit shop',
        },
        common: {
          sections: {
            detailedInformation: 'Detailed information',
            locationInformation: 'Location information',
            addressBook: 'Address book',
          },
        },
        placeholders: {
          name: 'Enter',
          code: 'Enter the shop code',
          email: 'Enter email',
          phone: 'Enter phone number',
          street: 'Enter street',
          city: 'Enter city',
          zip: 'Enter zip',
          country: 'Enter country',
          lat: 'Enter latitude',
          lon: 'Enter longitude',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        sapCode: 'SAP code',
        id: 'ID',
        address: 'Address',
        empty: 'Not specified',
        email: 'Email',
        phone: 'Phone',
        isActive: 'Active',
        isActiveEnum: {
          true: 'Active',
          false: 'Inactive',
        },
        lat: 'Latitude',
        lon: 'Longitude',
        country: 'Country',
        city: 'City',
        street: 'Street',
        zip: 'Zip',
        createdDate: 'Created date',
        editedDate: 'Edited date',
        groupAddress: 'Location information',
        createdByName: 'Created by',
        editedByName: 'Edited by',
        groupInfo: 'Detailed information',
        createNew: 'Create shop',
        state: 'State',
        messageAddressBooks: 'Address book',
        edited: 'Edited',
        created: 'Created',
      },
    },
  },
  de: {
    shop: {
      detail: {
        title: 'Geschäft #{code} - {name} ',
        tabs: {
          configurations: {
            title: 'Konfigurationen',
            actions: {
              create: 'Konfiguration erstellen',
            },
          },
        },
        actions: {
          showOnMap: 'Auf Karte anzeigen',
          activate: {
            confirm: 'Möchten Sie das Geschäft wirklich aktivieren?',
          },
          deactivate: {
            confirm: 'Möchten Sie das Geschäft wirklich deaktivieren?',
          },
        },
      },
      grid: {
        title: 'Geschäfte',
        actions: {
          create: {
            title: 'Erstellen',
          },
        },
        filters: {
          search: 'Suchen (min. 2 Zeichen)',
          isActive: 'Aktiv',
          isActiveEnum: {
            allOptions: 'Alle Zustände',
          },
        },
        advancedFilters: {
          title: 'Geschäfte filtern',
          search: 'Suchen',
          state: 'Zustand',
          placeholders: {
            search: 'Suchen (min. 2 Zeichen)',
          },
        },
      },
      form: {
        create: {
          title: 'Neues Geschäft',
        },
        update: {
          title: 'Aktualisierung des Geschäfts',
        },
        common: {
          sections: {
            detailedInformation: 'Detaillierte Informationen',
            locationInformation: 'Standortinformationen',
            addressBook: 'Adressbuch',
          },
        },
        placeholders: {
          name: 'Namen eingeben',
          code: 'Geschäftskennung eingeben',
          email: 'E-Mail eingeben',
          phone: 'Telefonnummer eingeben',
          street: 'Straße eingeben',
          city: 'Stadt eingeben',
          zip: 'Postleitzahl eingeben',
          country: 'Land eingeben',
          lat: 'Breitengrad eingeben',
          lon: 'Längengrad eingeben',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        sapCode: 'SAP-Code des Geschäfts',
        id: 'ID',
        address: 'Adresse',
        empty: 'Nicht angegeben',
        email: 'E-Mail',
        phone: 'Telefon',
        isActive: 'Aktiv',
        isActiveEnum: {
          true: 'Aktiv',
          false: 'Inaktiv',
        },
        lat: 'Breitengrad',
        lon: 'Längengrad',
        country: 'Land',
        city: 'Stadt',
        street: 'Straße',
        zip: 'Postleitzahl',
        createdDate: 'Erstellungsdatum',
        editedDate: 'Bearbeitungsdatum',
        groupAddress: 'Standortinformationen',
        createdByName: 'Erstellt von',
        editedByName: 'Bearbeitet von',
        groupInfo: 'Detaillierte Informationen',
        createNew: 'Neues Geschäft erstellen',
        state: 'Status',
        messageAddressBooks: 'Adressbuch',
        edited: 'Bearbeitet',
        created: 'Erstellt',
      },
    },
  },
};
