import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"all":"revert"} }
const _hoisted_2 = {
  key: 0,
  class: "text-danger px-4"
}
const _hoisted_3 = {
  key: 1,
  class: "px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loadingError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.loadingError), 1))
      : (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('layout.components.monacoEditor.loading')), 1))
        : _createCommentVNode("", true),
    _createElementVNode("div", _mergeProps({
      ref: "monacoRef",
      class: [{'monaco-editor--readonly': _ctx.readonly}]
    }, _ctx.$attrs), null, 16)
  ]))
}