import {
  inject,
  provide,
  Ref,
} from 'vue';

export const useTransitions = (...args: [{entering: Ref<boolean>, leaving: Ref<boolean>}] | []) => {
  if (args.length) {
    const [{leaving, entering}] = args;
    provide('transitionLeaving', leaving);
    provide('transitionEntering', entering);
  } else {
    return {
      transitionEntering: inject('transitionEntering', false),
      transitionLeaving: inject('transitionLeaving', false),
    };
  }
};
