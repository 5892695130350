
import Theme from '@ui/components/Theme.vue';
import {useAppTools} from '@ui/helpers/appTools';
import {mountDevTools} from '@ui/helpers/devTools';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import AppLoader from '@ui/modules/Core/AppLoader/AppLoader.vue';
import ModalConfirm from '@ui/modules/Core/Confirm/ModalConfirm.vue';
import ModalFeature from '@ui/modules/Core/Feature/ModalFeature.vue';
import NotificationToast from '@ui/modules/Core/Toast/NotificationToast.vue';
import {
  computed,
  defineComponent,
  provide,
} from 'vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  components: {
    NotificationToast,
    ModalConfirm,
    ModalFeature,
    AppLoader,
    Theme,
  },
  props: {
    appId: {
      type: null,
      required: true,
    },
  },
  setup(props) {
    provide('appId', props.appId);

    mountDevTools(); // TODO: dev condition!

    const {currentSystem} = useAuthStore();
    const i18n = useI18n();


    const appName = computed(() => {
      if (!currentSystem.value) {
        return i18n.t('app.appName');
      }

      return currentSystem.value.displayName;
    });

    const {setTitle} = useAppTools();
    setTitle(appName);
  },
});
