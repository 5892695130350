import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {
  createLocalStoragePersistentVariableWithMigration,
  withMigrations,
} from '@ui/helpers/persist';
import {createPersistentStore} from '@ui/helpers/store';
import {some} from 'lodash-es';

export interface IFeatureStore {
  formEntity: {
    data: string,
    schema: any,
    app: string,
  },
  list: Array<{
    name: string,
    parameters: Record<string, any>,
  }>,
}

const createInitState = (data?: Partial<IFeatureStore>): IFeatureStore => ({
  list: [],
  formEntity: null,
});

const BaseStore = createPersistentStore<IFeatureStore>(
  createInitState,
  withMigrations<IFeatureStore>([])(
    createLocalStoragePersistentVariableWithMigration('featureStore'),
    {
      deserialize: ({
        list,
      }) => ({
        list,
        formEntity: null,
      }),
    },
  ),
);

export class FeatureStore extends BaseStore {
  openForm = action((formEntity: IFeatureStore['formEntity']) => {
    formEntity.data = JSON.stringify(this.state.list, null, 2);

    this.state.formEntity = formEntity;
  });

  closeForm = action(() => {
    this.state.formEntity = null;
  });

  submitFormEntity = action(() => {
    this.state.list = JSON.parse(this.state.formEntity.data);
    this.closeForm();
  });

  formEntity = getter(() => this.state.formEntity);

  hasActiveFeature = action((featureName: string) => {
    return some(this.state.list, ({name}) => name === featureName);
  });

  getFeatureConfig = action((featureName: string) => {
    return this.state.list.find(({name}) => name === featureName);
  });
}

const storeIdentifier = 'FeatureStore';

export const configureFeatureStore = createConfigureStore<typeof FeatureStore>(storeIdentifier);
export const useFeatureStore = createUseStore(FeatureStore, storeIdentifier);
