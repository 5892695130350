export default {
  cs: {
    dashboard: {
      title: 'Správa kmenových dat POS',
    },
  },
  sk: {
    dashboard: {
      title: 'Správa kmeňových údajov POS',
    },
  },
  en: {
    dashboard: {
      title: 'POS Master Data Management',
    },
  },
  de: {
    dashboard: {
      title: 'Verwaltung der POS-Stammdaten',
    },
  },
};
