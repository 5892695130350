import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';


export default [
  {
    path: '/point-of-sale-attributes',
    name: 'point-of-sale-attributes',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.pointOfSaleAttributeList,
    },
  },
] as RegisterRoutesOptions;
