import {
  useValidations,
  useValidationTranslations,
} from '@ui/helpers/validations';
import {useI18n} from 'vue-i18n';
import * as yup from 'yup';

export const getFeatureValidations = ({getJsonSchema}) => () => {
  const i18n = useI18n();
  const {
    jsonSchemaValidation,
  } = useValidations();
  const {
    requiredTranslation,
  } = useValidationTranslations();

  return yup.object({
    data: yup.string()
      .nullable()
      .required(requiredTranslation(i18n.t('configuration.fields.data')))
      .test(jsonSchemaValidation(i18n.t('configuration.fields.data'), getJsonSchema)),
  });
};
