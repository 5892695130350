import {Entity} from '@designeo/apibundle-js/src/Entity/base';
import {createPersistentVariable, localStoragePersistentVariable} from '@designeo/js-helpers/src/index';
import {EntityType} from '@ui/constants/entityType';
import {identity, mapKeys} from 'lodash-es';

export const sessionStoragePersistentVariable = createPersistentVariable({localStorage: globalThis['sessionStorage']});

export const scenarioIdSessionStorage = sessionStoragePersistentVariable('scenarioId', undefined);

export const i18nLocaleLocalStorage = localStoragePersistentVariable('i18nLocale', null);

export interface IPersist<T> {
  get(): T,
  set(val: T): void,
  reset(): void,
}

type MigrationResult = {
  data: any,
  migration: string,
}

type Migration = {
  from: string,
  to: string,
  transform: (content: any) => any,
}

type Migrations = Migration[];

export const withMigrations = <T>(migrations: Migrations = []) => {
  const transform = (data: MigrationResult) => {
    const migrationsByFrom = mapKeys(migrations, 'from');
    let result = data;

    while (data && Object.prototype.hasOwnProperty.call(migrationsByFrom, data.migration)) {
      result = {
        data: migrationsByFrom[data.migration].transform(data),
        migration: null,
      };
    }

    return result;
  };

  return (
    persist: IPersist<any>,
    {
      serialize = identity,
      deserialize = identity,
    }: {
      serialize?: (val: T) => any,
      deserialize?: (val: any) => T,
    } = {},
  ) => ({
    get(): T {
      const val = transform(<MigrationResult>(persist.get()))?.data;
      if (!val) return null;
      return deserialize(val);
    },
    set(val: T) {
      persist.set(<MigrationResult>{
        data: val ? serialize(val) : null,
        migration: getCurrentBuildHash(),
      });
    },
    reset: persist.reset,
  });
};

export function getCurrentBuildHash(): string {
  try {
    return process.env.NODE_ENV === 'development' ? 'dev' : globalThis.document?.body?.dataset?.buildHash || null;
  } catch (err) {
    console.warn(`Failed to build hash`);
  }
  return null;
}

export const createLocalStoragePersistentVariableWithMigration = (name): IPersist<MigrationResult> => {
  return localStoragePersistentVariable(
    name,
    <MigrationResult>{
      data: null,
      migration: getCurrentBuildHash(),
    },
  );
};

export const createSessionStoragePersistentVariableWithMigration = (name): IPersist<MigrationResult> => {
  return sessionStoragePersistentVariable(
    name,
    <MigrationResult>{
      data: null,
      migration: getCurrentBuildHash(),
    },
  );
};

export type EntitySerialization = {
  entity: Entity<any, any>['_data'],
  entityType: EntityType | string,
}


export const deserializeEntity = <E extends Entity<any, any> = any>(val: EntitySerialization) => {
  let result;

  const resolve = (): E => {
    if (result) {
      return result;
    }

    return null;
  };

  const match = <GC extends typeof Entity<any, any>>(Ctor: GC, entityType: EntityType | string) => {
    if (val?.entityType === entityType) {
      result = new (Ctor)(val?.entity ?? {});
    }

    return {
      match,
      resolve,
    };
  };

  return {
    match,
  };
};


export const serializeEntity = <E extends Entity<any, any> = any>(entity: E) => {
  let result;

  const resolve = (): EntitySerialization => {
    if (result) {
      return {
        entity: result.entity.toJson(),
        entityType: result.entityType,
      };
    }

    return null;
  };

  const match = <GC extends typeof Entity<any, any>>(Ctor: GC, entityType: EntityType | string) => {
    if (entity instanceof Ctor) {
      result = {
        entity,
        entityType,
      };
    }

    return {
      match,
      resolve,
    };
  };

  return {
    match,
  };
};
