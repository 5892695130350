import {GridModel} from '@designeo/vue-grid/src/Model/GridModel';
import {Store} from '@designeo/vue-helpers';
import {PersistentStore} from '@ui/helpers/store';
import {
  inject,
  provide,
  Ref,
} from 'vue';
import {GridConfig} from './config';

const GridPageContext = 'GridPageContext';

type GridPageApi<
  GM extends GridModel<any> = GridModel<any>,
  GC extends Ref<GridConfig> = Ref<GridConfig>,
  S extends (Store<any> | PersistentStore<any>) = null
> = {
  gridModel: GM,
  gridConfig: GC,
  store: S,
  [key: string]: any,
}

export const injectGridPageContext = <
  GM extends GridModel<any> = GridModel<any>,
  GC extends Ref<GridConfig> = Ref<GridConfig>,
  S extends (Store<any> | PersistentStore<any>) = null
>() => {
  return <GridPageApi<GM, GC, S>>inject(GridPageContext);
};

export const provideGridPageContext = <
  GM extends GridModel<any>,
  GC extends Ref<GridConfig>,
  S extends (Store<any> | PersistentStore<any>) = null
>(
  gridModel,
  gridConfig,
  store?,
) => {
  const context: GridPageApi<GM, GC, S> = {
    gridModel,
    gridConfig,
    store,
  };

  provide(GridPageContext, context);
};

export const useGridPage = <
  GM extends GridModel<any>,
  GC extends Ref<GridConfig>,
  S extends (Store<any> | PersistentStore<any>) = null
>(
  gridModel: GM,
  gridConfig: GC,
  {store = null}: {store?: S} = {},
) => {
  provideGridPageContext(gridModel, gridConfig, store);

  return {
    gridModel,
    gridConfig,
    store,
  };
};
