import {
  action, Store,
} from '@designeo/vue-helpers';
import {createEmitterReaction} from '@ui/helpers/event';
import {IPersist} from '@ui/helpers/persist';
import {debounce} from 'lodash-es';
import {
  EffectScope,
  toRaw,
  watch,
} from 'vue';

const persistentStoreEffectScope = new EffectScope(true);

export class PersistentStore<T extends object> extends Store<T> {
  public persist: () => Promise<void>;
  public persistWithDebounce: ReturnType<typeof debounce>;
  constructor(state: T, persist: IPersist<T>, {autoPersist = true} = {}) {
    super(persist.get() || state);

    this.persist = async () => {
      this.persistWithDebounce.cancel();

      persist.set(toRaw(this.state));
    };

    this.persistWithDebounce = debounce(async () => {
      this.persist();
    }, 400);

    if (autoPersist) {
      persistentStoreEffectScope.run(() => {
        watch(
          () => this.state,
          () => {
            this.persistWithDebounce();
          },
          {
            flush: 'sync', // https://docs.w3cub.com/vue~3/api/instance-methods#watch
            deep: true,
          },
        );
      });
    }
  }
}


export function createStore <
  X extends object,
  E extends string = null
>(
  createInitState: () => X,
) {
  return class BaseStore extends Store<X> {
    constructor() {
      super(createInitState());
    }

    resetState = action(() => {
      this.state = Object.assign(this.state, createInitState());
    });

    reactOnEvent = action(createEmitterReaction<E>(this));
  };
}

export function createPersistentStore <
  X extends object,
  E extends string = null
>(createInitState: () => X, persist: IPersist<X>) {
  return class BasePersistentStore extends PersistentStore<X> {
    constructor() {
      super(createInitState(), persist);
    }

    resetState = action(() => {
      this.state = Object.assign(this.state, createInitState());
    });

    reactOnEvent = action(createEmitterReaction<E>(this));
  };
}
