import {ImportState} from '@api/posMiddlewareCore/constants/importState';
import {ImportType} from '@api/posMiddlewareCore/constants/importType';

export default {
  cs: {
    import: {
      grid: {
        title: 'Dávkové importy',
        filters: {
          id: 'id',
          name: 'Název souboru',
          importState: 'Stav',
          type: 'Typ souboru',
          trigger: 'trigger',
          tag: 'Štítek',
          processedAt: 'Zpracováno',
          createdByName: 'createdByName',
          createdById: 'createdById',
          createdAt: 'Vytvořeno',
        },
        advancedFilters: {
          title: 'Filtrovat dávkové importy',
          search: 'Vyhledat',
          placeholders: {
            id: 'id',
            name: 'Zadejte název souboru',
            importState: 'Vyberte stav',
            type: 'Vyberte typ souboru',
            trigger: 'trigger',
            tag: 'Zadejte štítek',
          },
        },
        actions: {
          upload: {
            title: 'Nový soubor',
          },
        },
      },
      form: {
        upload: {
          title: 'Nahrát dávkový import',
        },
        placeholders: {
          file: 'Vyberte dávkový soubor',
          type: 'Vyberte typ souboru',
          tag: 'Zadejte štítek',
        },
      },
      fields: {
        file: 'Soubor',
        id: 'id',
        name: 'Název souboru',
        importState: 'Stav',
        importStateEnum: {
          [ImportState.error]: 'Chyba',
          [ImportState.processed]: 'Zpracováno',
        },
        type: 'Typ souboru',
        typeEnum: {
          [ImportType.articles]: 'PLU',
          [ImportType.historicArticles]: 'PLU historické',
          [ImportType.cashiersJson]: 'Pokladní (json)',
          [ImportType.cashiersCsv]: 'Pokladní (csv)',
          [ImportType.press]: 'Tiskoviny',
        },
        trigger: 'trigger',
        tag: 'Štítek',
        processedAt: 'Zpracováno',
        createdByName: 'createdByName',
        createdById: 'createdById',
        createdAt: 'Vytvořeno',
      },
      modalUploadErrorLogDetail: {
        title: 'Detail chyby souboru: {fileName}',
      },
    },
  },
  sk: {
    import: {
      grid: {
        title: 'Hromadné importy',
        filters: {
          id: 'id',
          name: 'Názov súboru',
          importState: 'Stav',
          type: 'Typ súboru',
          trigger: 'trigger',
          tag: 'Štítok',
          processedAt: 'Spracované',
          createdByName: 'createdByName',
          createdById: 'createdById',
          createdAt: 'Vytvorené',
        },
        advancedFilters: {
          title: 'Filtrovať hromadné importy',
          search: 'Vyhľadať',
          placeholders: {
            id: 'id',
            name: 'Zadajte názov súboru',
            importState: 'Vyberte stav',
            type: 'Vyberte typ súboru',
            trigger: 'trigger',
            tag: 'Zadajte štítok',
          },
        },
        actions: {
          upload: {
            title: 'Nový súbor',
          },
        },
      },
      form: {
        upload: {
          title: 'Nahrať hromadný import',
        },
        placeholders: {
          file: 'Vyberte hromadný súbor',
          type: 'Vyberte typ súboru',
          tag: 'Zadajte štítok',
        },
      },
      fields: {
        file: 'Súbor',
        id: 'id',
        name: 'Názov súboru',
        importState: 'Stav',
        importStateEnum: {
          [ImportState.error]: 'Chyba',
          [ImportState.processed]: 'Spracované',
        },
        type: 'Typ súboru',
        typeEnum: {
          [ImportType.articles]: 'PLU',
          [ImportType.historicArticles]: 'PLU historické',
          [ImportType.cashiersJson]: 'Pokladní (json)',
          [ImportType.cashiersCsv]: 'Pokladní (csv)',
          [ImportType.press]: 'Tlačoviny',
        },
        trigger: 'trigger',
        tag: 'Štítok',
        processedAt: 'Spracované',
        createdByName: 'createdByName',
        createdById: 'createdById',
        createdAt: 'Vytvorené',
      },
      modalUploadErrorLogDetail: {
        title: 'Detail chyby súboru: {fileName}',
      },
    },
  },
  en: {
    import: {
      grid: {
        title: 'Batch imports',
        filters: {
          id: 'id',
          name: 'File name',
          importState: 'State',
          type: 'File type',
          trigger: 'trigger',
          tag: 'Tag',
          processedAt: 'Processed',
          createdByName: 'createdByName',
          createdById: 'createdById',
          createdAt: 'Created',
        },
        advancedFilters: {
          title: 'Filter batch imports',
          search: 'Search',
          placeholders: {
            id: 'id',
            name: 'Enter file name',
            importState: 'Select state',
            type: 'Select file type',
            trigger: 'trigger',
            tag: 'Enter tag',
          },
        },
        actions: {
          upload: {
            title: 'New file',
          },
        },
      },
      form: {
        upload: {
          title: 'Upload batch import',
        },
        placeholders: {
          file: 'Select batch file',
          type: 'Select file type',
          tag: 'Enter tag',
        },
      },
      fields: {
        file: 'File',
        id: 'id',
        name: 'File name',
        importState: 'State',
        importStateEnum: {
          [ImportState.error]: 'Error',
          [ImportState.processed]: 'Processed',
        },
        type: 'File type',
        typeEnum: {
          [ImportType.articles]: 'PLU',
          [ImportType.historicArticles]: 'PLU historical',
          [ImportType.cashiersJson]: 'Cashiers (json)',
          [ImportType.cashiersCsv]: 'Cashiers (csv)',
          [ImportType.press]: 'Press',
        },
        trigger: 'trigger',
        tag: 'Tag',
        processedAt: 'Processed',
        createdByName: 'createdByName',
        createdById: 'createdById',
        createdAt: 'Created',
      },
      modalUploadErrorLogDetail: {
        title: 'File error detail: {fileName}',
      },
    },
  },
  de: {
    import: {
      grid: {
        title: 'Batch-Importe',
        filters: {
          id: 'id',
          name: 'Dateiname',
          importState: 'Status',
          type: 'Dateityp',
          trigger: 'Auslöser',
          tag: 'Tag',
          processedAt: 'Verarbeitet am',
          createdByName: 'createdByName',
          createdById: 'createdById',
          createdAt: 'Erstellt am',
        },
        advancedFilters: {
          title: 'Batch-Importe filtern',
          search: 'Suchen',
          placeholders: {
            id: 'id',
            name: 'Dateinamen eingeben',
            importState: 'Status auswählen',
            type: 'Dateityp auswählen',
            trigger: 'Auslöser',
            tag: 'Tag eingeben',
          },
        },
        actions: {
          upload: {
            title: 'Neue Datei',
          },
        },
      },
      form: {
        upload: {
          title: 'Batch-Import hochladen',
        },
        placeholders: {
          file: 'Batch-Datei auswählen',
          type: 'Dateityp auswählen',
          tag: 'Tag eingeben',
        },
      },
      fields: {
        file: 'Datei',
        id: 'id',
        name: 'Dateiname',
        importState: 'Status',
        importStateEnum: {
          [ImportState.error]: 'Fehler',
          [ImportState.processed]: 'Verarbeitet',
        },
        type: 'Dateityp',
        typeEnum: {
          [ImportType.articles]: 'PLU',
          [ImportType.historicArticles]: 'PLU historisch',
          [ImportType.cashiersJson]: 'Kassierer (json)',
          [ImportType.cashiersCsv]: 'Kassierer (csv)',
          [ImportType.press]: 'Presse',
        },
        trigger: 'Auslöser',
        tag: 'Tag',
        processedAt: 'Verarbeitet am',
        createdByName: 'createdByName',
        createdById: 'createdById',
        createdAt: 'Erstellt am',
      },
      modalUploadErrorLogDetail: {
        title: 'Detail zum Fehlerprotokoll der Datei: {fileName}',
      },
    },
  },
};
