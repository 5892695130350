export default {
  cs: {
    middlewareConfiguration: {
      form: {
        update: {
          title: 'Konfigurace middleware',
        },
      },
      fields: {
        configuration: 'Konfigurace',
      },
    },
  },
  sk: {
    middlewareConfiguration: {
      form: {
        update: {
          title: 'Konfigurácia middleware',
        },
      },
      fields: {
        configuration: 'Konfigurácia',
      },
    },
  },
  en: {
    middlewareConfiguration: {
      form: {
        update: {
          title: 'Middleware Configuration',
        },
      },
      fields: {
        configuration: 'Configuration',
      },
    },
  },
  de: {
    middlewareConfiguration: {
      form: {
        update: {
          title: 'Middleware-Konfiguration',
        },
      },
      fields: {
        configuration: 'Konfiguration',
      },
    },
  },
};
