import {AppSpecificApi} from '@api/helpers';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {parseJwt} from '@ui/helpers/jwt';
import {getMetaTagContent} from '@ui/helpers/meta';
import {createAuthCallbackRedirect, RedirectPathName} from '@ui/helpers/redirect';
import {AxiosInstance} from 'axios';
import {find, reject} from 'lodash-es';
import {
  User,
  UserManager,
  Log,
} from 'oidc-client';

const identityUrl = '/identity';

Log.logger = console;
Log.level = Log.INFO;

export class AuthStore extends Store<{
  identityUserManager: UserManager,
  identityUser?: User,
  systems: any[],
  links: any[],
  roles: string[],
  appSpecificApi: AppSpecificApi<['identifyMe']>,
  aclMap: {[key: string]: string},
}> {
  private identityAxios: AxiosInstance;
  constructor() {
    super({
      identityUserManager: new UserManager({
        authority: identityUrl,
        client_id: getMetaTagContent('client-id'),
        response_type: 'code',
        redirect_uri: `${window.location.origin}${RedirectPathName.redirectLoginCallback}`,
        silent_redirect_uri: `${window.location.origin}${RedirectPathName.redirectLoginCallback}`,
        post_logout_redirect_uri: `${window.location.origin}${RedirectPathName.redirectLogin}`,
        scope: 'offline_access',
        loadUserInfo: true,
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        get extraQueryParams() {
          return {
            custom_callback_uri: createAuthCallbackRedirect(),
          };
        },
      }),
      identityUser: null,
      systems: [],
      links: [],
      roles: [],
      appSpecificApi: new AppSpecificApi(),
      aclMap: null,
    });

    this.identityAxios = require('axios').create({
      baseURL: identityUrl,
    });

    this.identityAxios.interceptors.request.use((config) => {
      config.headers['Authorization'] = `bearer ${this.state.identityUser?.access_token}`;
      return config;
    });

    this.identityUserManager.value.events.addUserLoaded((user) => {
      this.setIdentityUser(user);
    });
  }

  identityUserManager = getter(() => this.state.identityUserManager);

  user = getter(() => {
    if (!this.state.identityUser) {
      return null;
    }

    return parseJwt(this.state.identityUser.access_token);
  });

  systems = getter(() => this.state.systems);

  otherSystems = getter(() => {
    return reject(this.state.systems, ({baseUri}) => {
      try {
        return new URL(baseUri).origin === window.location.origin;
      } catch (e) {
        console.error(e);
        return false;
      }
    });
  });

  currentSystem = getter(() => {
    return find(this.state.systems, ({baseUri}) => {
      try {
        return new URL(baseUri).origin === window.location.origin;
      } catch (e) {
        console.error(e);
        return false;
      }
    });
  });

  links = getter(() => this.state.links);

  hasAccess = action((aclKey: any, links = this.state.links): boolean => {
    if (!links) {
      return true;
    }

    return links.some(({operationId}) => operationId === this.state.aclMap[aclKey]);
  });

  hasRole = action((role: string): boolean => {
    return (this.state.roles).includes(role);
  });

  authorizationHeader = getter(() => this.state.identityUser?.access_token);

  ensureIdentityUser = action(async () => {
    if (this.state.identityUser) return;
    await this.fetchIdentityUser();
  });


  fetchIdentityUser = action(async () => {
    this.setIdentityUser(await this.identityUserManager.value.getUser());
    try {
      const {links, data} = await this.state.appSpecificApi.call.identifyMe();
      this.state.links = links ?? [];
      this.state.roles = data.roles ?? [];
      await this.loadSystems();
    } catch (e) {
      console.error(e);
    }
    if (!this.state.identityUser) {
      throw new Error('User null');
    }
  });

  setIdentityUser = action((user) => this.state.identityUser = user);

  loadSystems = action(
    async () => {
      const result = await this.identityAxios('/api/v1/account/systems');
      this.state.systems = result.data;
    },
  );

  setAppSpecificApi = action((key: Parameters<typeof this.state.appSpecificApi.setApi>[0], fetch) => {
    this.state.appSpecificApi.setApi(key, fetch);
  });

  setAclMap = action((map) => {
    this.state.aclMap = map;
  });
}

const storeSymbol = Symbol();

export const configureAuthStore = createConfigureStore<typeof AuthStore>(storeSymbol);
export const useAuthStore = createUseStore(AuthStore, storeSymbol);
