export default {
  cs: {
    transaction: {
      grid: {
        title: 'Transakce',
        filters: {
          shopPosCode: 'Prodejna a pokladna',
          documentDateFrom: 'Datum od',
          documentDateTo: 'Datum do',
          cashierSearch: 'Pokladní',
          documentNumber: 'Číslo dokladu',
        },
        advancedFilters: {
          title: 'Filtrovat transakce',
          search: 'Vyhledat',
          placeholders: {
            shopPosCode: 'Zadejte prodejnu/pokladnu',
            documentDateFrom: 'Zadejte datum od',
            documentDateTo: 'Zadejte datum do',
            cashierSearch: 'Zadejte jméno nebo osobní číslo pokladní',
            documentNumber: 'Zadejte číslo dokladu',
          },
        },
      },
      fields: {
        documentDate: 'Datum a čas dokladu',
        documentNumber: 'Číslo dokladu',
        totalPrice: 'Hodnota',
        shopPosCode: 'Pokladna',
        cashierNameAndPersonalNumber: 'Pokladní',
        customerNumber: 'Číslo zákazníka',
      },
      printoutDetail: {
        title: 'Výtisk',
        notFound: 'Žádný výtisk nenalezen',
        print: 'Tisk',
      },
    },
  },
  sk: {
    transaction: {
      grid: {
        title: 'Transakcie',
        filters: {
          shopPosCode: 'Predajňa a pokladňa',
          documentDateFrom: 'Dátum od',
          documentDateTo: 'Dátum do',
          cashierSearch: 'Pokladní',
          documentNumber: 'Číslo dokladu',
        },
        advancedFilters: {
          title: 'Filtrovať transakcie',
          search: 'Hľadať',
          placeholders: {
            shopPosCode: 'Zadajte Predajňu/pokladňu',
            documentDateFrom: 'Zadajte začiatočný dátum',
            documentDateTo: 'Zadajte koncový dátum',
            cashierSearch: 'Zadajte meno alebo osobné číslo pokladní',
            documentNumber: 'Zadajte číslo dokladu',
          },
        },
      },
      fields: {
        documentDate: 'Dátum a čas dokladu',
        documentNumber: 'Číslo dokladu',
        totalPrice: 'Celková hodnota',
        shopPosCode: 'Pokladňa',
        cashierNameAndPersonalNumber: 'Pokladní',
        customerNumber: 'Číslo zákazníka',
      },
      printoutDetail: {
        title: 'Výtlačok',
        notFound: 'Žiaden výtlačok nenájdený',
        print: 'Tlač',
      },
    },
  },
  en: {
    transaction: {
      grid: {
        title: 'Transactions',
        filters: {
          shopPosCode: 'Shop and POS code',
          documentDateFrom: 'Date from',
          documentDateTo: 'Date to',
          cashierSearch: 'Cashier',
          documentNumber: 'Document number',
        },
        advancedFilters: {
          title: 'Filter Transactions',
          search: 'Search',
          placeholders: {
            shopPosCode: 'Enter shop/cash register',
            documentDateFrom: 'Enter start date',
            documentDateTo: 'Enter end date',
            cashierSearch: 'Enter cashier name or ID',
            documentNumber: 'Enter document number',
          },
        },
      },
      fields: {
        documentDate: 'Document Date and Time',
        documentNumber: 'Document Number',
        totalPrice: 'Total Value',
        shopPosCode: 'Cash Register',
        cashierNameAndPersonalNumber: 'Cashier',
        customerNumber: 'customerNumber Number',
      },
      printoutDetail: {
        title: 'Printout',
        notFound: 'No printout found',
        print: 'Print',
      },
    },
  },
  de: {
    transaction: {
      grid: {
        title: 'Transaktionen',
        filters: {
          shopPosCode: 'Geschäft und Kasse',
          documentDateFrom: 'Datum von',
          documentDateTo: 'Datum bis',
          cashierSearch: 'Kassierer',
          documentNumber: 'Belegnummer',
        },
        advancedFilters: {
          title: 'Transaktionen filtern',
          search: 'Suchen',
          placeholders: {
            shopPosCode: 'Geschäft/Kasse eingeben',
            documentDateFrom: 'Anfangsdatum eingeben',
            documentDateTo: 'Enddatum eingeben',
            cashierSearch: 'Name oder Personalnummer des Kassierers eingeben',
            documentNumber: 'Belegnummer eingeben',
          },
        },
      },
      fields: {
        documentDate: 'Dokumentdatum und -uhrzeit',
        documentNumber: 'Dokumentnummer',
        totalPrice: 'Gesamtwert',
        shopPosCode: 'Kasse',
        cashierNameAndPersonalNumber: 'Kassierer',
        customerNumber: 'Kundennummer',
      },
      printoutDetail: {
        title: 'Ausdruck',
        notFound: 'Kein Ausdruck gefunden',
        print: 'Drucken',
      },
    },
  },
};
