export default {
  cs: {
    pointOfSaleAttribute: {
      grid: {
        title: 'POS Atributy',
        filters: {
          search: 'Vyhledat',
        },
        actions: {
          create: {
            title: 'Založit atribut',
          },
        },
      },
      form: {
        create: {
          title: 'Založit POS atribut',
        },
        update: {
          title: 'Editovat POS atribut {name}',
        },
        assign: {
          title: 'Úprava přiřazených atributů',
        },
        placeholders: {
          name: 'Zadejte název atributu',
          attributes: 'Vyberte atributy',
          attribute: 'Vyberte atribut',
          validFrom: 'Vyberte platnost od',
          validTill: 'Vyberte platnost do',
        },
        actions: {
          changeValidity: {
            title: 'Změnit platnost',
          },
        },
      },
      fields: {
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        attribute: 'Atribut',
        name: 'Název',
      },
    },
  },
  sk: {
    pointOfSaleAttribute: {
      grid: {
        title: 'POS Atribúty',
        filters: {
          search: 'Vyhľadať',
        },
        actions: {
          create: {
            title: 'Vytvoriť atribút',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvoriť POS atribút',
        },
        update: {
          title: 'Upraviť POS atribút {name}',
        },
        assign: {
          title: 'Upraviť priradené atribúty',
        },
        placeholders: {
          name: 'Zadajte názov atribútu',
          attributes: 'Vyberte atribúty',
          attribute: 'Vyberte atribút',
          validFrom: 'Vyberte platnosť od',
          validTill: 'Vyberte platnosť do',
        },
        actions: {
          changeValidity: {
            title: 'Zmeniť platnosť',
          },
        },
      },
      fields: {
        validFrom: 'Platnosť od',
        validTill: 'Platnosť do',
        attribute: 'Atribút',
        name: 'Názov',
      },
    },
  },
  en: {
    pointOfSaleAttribute: {
      grid: {
        title: 'POS Attributes',
        filters: {
          search: 'Search',
        },
        actions: {
          create: {
            title: 'Create attribute',
          },
        },
      },
      form: {
        create: {
          title: 'Create POS attribute',
        },
        update: {
          title: 'Update POS attribute {name}',
        },
        assign: {
          title: 'Update assigned attributes',
        },
        placeholders: {
          name: 'Enter attribute name',
          attributes: 'Select attributes',
          attribute: 'Select attribute',
          validFrom: 'Select valid from',
          validTill: 'Select valid till',
        },
        actions: {
          changeValidity: {
            title: 'Change validity',
          },
        },
      },
      fields: {
        validFrom: 'Valid from',
        validTill: 'Valid till',
        attribute: 'Attribute',
        name: 'Name',
      },
    },
  },
  de: {
    pointOfSaleAttribute: {
      grid: {
        title: 'POS Attribute',
        filters: {
          search: 'Suchen',
        },
        actions: {
          create: {
            title: 'Attribut erstellen',
          },
        },
      },
      form: {
        create: {
          title: 'POS-Attribut erstellen',
        },
        update: {
          title: 'POS-Attribut bearbeiten {name}',
        },
        assign: {
          title: 'Zugewiesene Attribute bearbeiten',
        },
        placeholders: {
          name: 'Geben Sie den Attributnamen ein',
          attributes: 'Wählen Sie Attribute aus',
          attribute: 'Wählen Sie ein Attribut aus',
          validFrom: 'Wählen Sie die Gültigkeit ab',
          validTill: 'Wählen Sie die Gültigkeit bis',
        },
        actions: {
          changeValidity: {
            title: 'Gültigkeit ändern',
          },
        },
      },
      fields: {
        validFrom: 'Gültigkeit ab',
        validTill: 'Gültigkeit bis',
        attribute: 'Attribut',
        name: 'Name',
      },
    },
  },
};
