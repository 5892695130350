import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import apiV1AdminVoucherListGet from '@api/posMiddlewareCore/action/apiV1AdminVoucherListGet';
import apiV1AdminVoucherListGetAdmin from '@api/posMiddlewareCore/action/apiV1AdminVoucherListGetAdmin';
import VoucherPreviewDtoIEnvelope from '@api/posMiddlewareCore/entity/VoucherPreviewDtoIEnvelope';
import {FilterOperation} from '@designeo/vue-grid/src/Model/Filter';
import {GridModel} from '@designeo/vue-grid/src/Model/GridModel';
import {
  action,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {useStaticEnums} from '@middleware-ui/helpers/staticEnums';
import GridCellState from '@middleware-ui/modules/Voucher/Grid/gridCells/GridCellState.vue';
import GridCellDate from '@ui/components/grid/gridCells/GridCellDate.vue';
import GridCellDateTime from '@ui/components/grid/gridCells/GridCellDateTime.vue';
import GridCellLink from '@ui/components/grid/gridCells/GridCellLink.vue';
import InputSelect from '@ui/components/input/InputSelect.vue';
import InputString from '@ui/components/input/InputString.vue';
import {withFilters} from '@ui/helpers/grid/api';
import {GridConfig} from '@ui/helpers/grid/config';
import {injectGridPageContext, useGridPage} from '@ui/helpers/grid/detail';
import {HLFilterWrapper, IGridAdvancedFilters} from '@ui/helpers/grid/filter';
import {createGridUrlPersister} from '@ui/helpers/grid/persist';
import {createStore} from '@ui/helpers/store';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {computed, Ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';


export enum GridPageEvents {
  adminViewChanged = 'adminViewChanged',
}


interface IGridPageStore {
  adminView: boolean,
}

const createInitState = (): IGridPageStore => ({
  adminView: null,
});

const BaseStore = createStore<IGridPageStore, GridPageEvents>(createInitState);

class GridPageStore extends BaseStore {
  setAdminView = action((val: boolean) => {
    this.state.adminView = val;
  });
  adminView = getter(() => this.state.adminView);
}

export const store = createUseStore(GridPageStore, 'vouchersGrid')();

export const injectVoucherGridContext = () =>
  injectGridPageContext<GridModel<any>, Ref<GridConfig>, typeof store>();

export const useVoucherGridPage = () => {
  const i18n = useI18n();
  const router = useRouter();
  const authStore = useAuthStore();

  const {voucherStateOptions} = useStaticEnums();

  const gridModel = new GridModel(
    'vouchers',
    withFilters((config) => {
      if (store.adminView.value) {
        return apiV1AdminVoucherListGetAdmin(config);
      }

      return apiV1AdminVoucherListGet(config);
    }),
    {
      defaultPageSize: 50,
      persisters: [createGridUrlPersister('vouchers')],
      // defaultSort: [ // TODO: fix sorting
      //   {
      //     key: 'usedAt',
      //     direction: 'ASC',
      //   },
      // ],
    },
  );

  const gridConfig = computed(
    () => ({
      columns: [
        {
          key: 'data.voucherNumber',
          label: i18n.t('voucher.fields.voucherNumber'),
          displayComponent: GridCellLink,
          displayComponentConfig: {to: {name: 'voucher'}},
        },
        {
          key: 'data.emission.code',
          label: i18n.t('voucher.fields.emission.code'),
        },
        {
          key: 'data.emission.name',
          label: i18n.t('voucher.fields.emission.name'),
        },
        {
          key: 'data.validFrom',
          label: i18n.t('voucher.fields.validFrom'),
          displayComponent: GridCellDate,
        },
        {
          key: 'data.validTill',
          label: i18n.t('voucher.fields.validTill'),
          displayComponent: GridCellDate,
        },
        {
          key: 'data.state',
          label: i18n.t('voucher.fields.state'),
          displayComponent: GridCellState,
        },
        {
          key: 'data.usedAt',
          // sortKey: 'usedAt',
          label: i18n.t('voucher.fields.usedAt'),
          displayComponent: GridCellDateTime,
        },
        {
          key: 'data.usageCountWithMax',
          label: i18n.t('voucher.fields.usageCount'),
        },
      ],
      actions: [
        {
          action: async (item: VoucherPreviewDtoIEnvelope) => {
            await router.push({name: 'voucher', params: {id: item.data.id}});
          },
          icon: 'PhMagnifyingGlass',
          title: i18n.t('action.open'),
          isAllowed: (item: VoucherPreviewDtoIEnvelope) =>
            authStore.hasAccess(AclKey.voucherGet, item.links),
        },
      ],
      filters: [
        {
          key: 'voucherNumber',
          inputComponent: HLFilterWrapper(InputString),
          inputComponentOptions: {
            placeholder: i18n.t('voucher.grid.filters.voucherNumber'),
          },
          operation: FilterOperation.LIKE,
        },
        {
          key: 'emission.code',
          inputComponent: HLFilterWrapper(InputString),
          inputComponentOptions: {
            placeholder: i18n.t('voucher.grid.filters.emission.code'),
          },
          operation: FilterOperation.LIKE,
        },
        {
          key: 'state',
          operation: FilterOperation.EQUAL,
          inputComponent: InputSelect,
          inputComponentOptions: {
            options: voucherStateOptions.value,
            placeholder: i18n.t('voucher.grid.filters.stateEnum.allOptions'),
          },
        },
      ],
    }) as GridConfig,
  );

  const gridAdvancedFilters = computed<IGridAdvancedFilters>(() => {
    return undefined;
  });

  const grid = useGridPage(
    gridModel,
    gridConfig,
    {store},
  );

  store.reactOnEvent(GridPageEvents.adminViewChanged, async () => {
    await gridModel.update();
  });

  return {
    ...grid,
    gridAdvancedFilters,
  };
};
