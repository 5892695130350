
export default {
  cs: {
    cashierRole: {
      detail: {
        title: '{name}',
      },
      grid: {
        title: 'Role',
        actions: {
          create: {
            title: 'Založit',
          },
          remove: {
            confirm: 'Opravdu chcete smazat roli "{name}"?',
          },
        },
        filters: {
          name: 'Vyhledat název',
          isDefaultEnum: {
            allOptions: 'Všechny role',
          },
        },
      },
      form: {
        create: {
          title: 'Nová role',
        },
        update: {
          title: 'Úprava role',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          externalId: 'Zadejte externí ID',
          isDefault: 'Výchozí role',
        },
      },
      fields: {
        name: 'Název',
        edited: 'Upraveno',
        code: 'Kód',
        externalId: 'Externí ID',
        isDefault: 'Výchozí role',
        isDefaultEnum: {
          true: 'Pouze výchozí role',
          false: 'Bez výchozích rolí',
        },
      },
    },
  },
  sk: {
    cashierRole: {
      detail: {
        title: '{name}',
      },
      grid: {
        title: 'Role',
        actions: {
          create: {
            title: 'Vytvoriť',
          },
          remove: {
            confirm: 'Naozaj chcete odstrániť rolu "{name}"?',
          },
        },
        filters: {
          name: 'Vyhľadať názov',
          isDefaultEnum: {
            allOptions: 'Všetky roly',
          },
        },
      },
      form: {
        create: {
          title: 'Nová rola',
        },
        update: {
          title: 'Upraviť rolu',
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód',
          externalId: 'Zadajte externé ID',
          isDefault: 'Východzia rola',
        },
      },
      fields: {
        name: 'Názov',
        edited: 'Upravené',
        code: 'Kód',
        externalId: 'Externé ID',
        isDefault: 'Východzia rola',
        isDefaultEnum: {
          true: 'Iba východzie role',
          false: 'Bez východzích rolí',
        },
      },
    },
  },
  en: {
    cashierRole: {
      detail: {
        title: '{name}',
      },
      grid: {
        title: 'Role',
        actions: {
          create: {
            title: 'Create',
          },
          remove: {
            confirm: 'Do you really want to remove role "{name}"?',
          },
        },
        filters: {
          name: 'Search name',
          isDefaultEnum: {
            allOptions: 'All roles',
          },
        },
      },
      form: {
        create: {
          title: 'New role',
        },
        update: {
          title: 'Edit role',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
          externalId: 'Enter external ID',
          isDefault: 'Default role',
        },
      },
      fields: {
        name: 'Name',
        edited: 'Edited',
        code: 'Code',
        externalId: 'External ID',
        isDefault: 'Default role',
        isDefaultEnum: {
          true: 'Only default roles',
          false: 'Without default roles',
        },
      },
    },
  },
  de: {
    cashierRole: {
      detail: {
        title: '{name}',
      },
      grid: {
        title: 'Rolle',
        actions: {
          create: {
            title: 'Erstellen',
          },
          remove: {
            confirm: 'Möchten Sie die Rolle "{name}" wirklich entfernen?',
          },
        },
        filters: {
          name: 'Suche nach Namen',
          isDefaultEnum: {
            allOptions: 'Alle Rollen',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Rolle',
        },
        update: {
          title: 'Rolle bearbeiten',
        },
        placeholders: {
          name: 'Namen eingeben',
          code: 'Code eingeben',
          externalId: 'Externe ID eingeben',
          isDefault: 'Standardrolle',
        },
      },
      fields: {
        name: 'Name',
        edited: 'Bearbeitet',
        code: 'Code',
        externalId: 'Externe ID',
        isDefault: 'Standardrolle',
        isDefaultEnum: {
          true: 'Nur Standardrollen',
          false: 'Ohne Standardrollen',
        },
      },
    },
  },
};
