import {
  createConfigureStore,
  createUseStore,
  getter,
  action,
} from '@designeo/vue-helpers';
import {
  createSessionStoragePersistentVariableWithMigration,
  withMigrations,
} from '@ui/helpers/persist';
import {createPersistentStore} from '@ui/helpers/store';

export interface IAsideStore {
  collapsed: boolean,
}

const createInitState = (data?: Partial<IAsideStore>): IAsideStore => ({
  collapsed: false,
});

const BaseStore = createPersistentStore<IAsideStore>(
  createInitState,
  withMigrations<IAsideStore>([])(
    createSessionStoragePersistentVariableWithMigration('asideStore'),
  ),
);

export class AsideStore extends BaseStore {
  setCollapsed = action((collapsed: boolean) => {
    this.state.collapsed = collapsed;
  });

  collapsed = getter(() => {
    return this.state.collapsed;
  });
}

const storeIdentifier = 'AsideStore';

export const configureAsideStore = createConfigureStore<typeof AsideStore>(storeIdentifier);
export const useAsideStore = createUseStore(AsideStore, storeIdentifier);
