import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-darkest-gray space-y-14" }
const _hoisted_2 = { class: "typo-subtitle-1 mb-5" }
const _hoisted_3 = { class: "typo-subtitle-1 mb-5" }
const _hoisted_4 = { class: "grid grid-cols-[1fr_3fr] gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    class: "modal--4xl",
    onClose: _ctx.onClose
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.common.heading')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.common.text')), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.shortcuts.heading')), 1),
          _createElementVNode("dl", _hoisted_4, [
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.shortcuts.ctrlSpace.keyBind')), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.shortcuts.ctrlSpace.description')), 1),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.shortcuts.ctrlF.keyBind')), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.$t('form.jsonInput.helpModal.shortcuts.ctrlF.description')), 1)
          ])
        ])
      ])
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "btn-outline-green btn-lg",
        onClick: _ctx.onClose
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('action.close')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}