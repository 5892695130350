import {PointOfSaleSynchronizationType} from '@api/posMiddlewareCore/constants/pointOfSaleSynchronizationType';
import {SyncedStatus} from '@api/posMiddlewareCore/constants/pointOfSaleSyncStatus';

export default {
  cs: {
    pointOfSaleSynchronization: {
      fields: {
        titleKey: 'Oblast',
        titleKeyEnum: {
          [PointOfSaleSynchronizationType.articles]: 'Artikly',
          [PointOfSaleSynchronizationType.promotions]: 'Promoce',
          [PointOfSaleSynchronizationType.posData]: 'Základní data POS',
          [PointOfSaleSynchronizationType.configurations]: 'Konfigurace',
          [PointOfSaleSynchronizationType.cashiers]: 'Pokladní',
          noData: 'Žádná data',
        },
        isUpToDate: 'Stav',
        isUpToDateEnum: {
          true: 'Aktuální',
          false: 'Neaktuální',
        },
        lastAppliedAt: 'Poslední data POS',
        nonSyncedRequestedAt: 'Staženo na POS',
        nonSyncedCreatedAt: 'Poslední data z MW',
        isSynced: 'Stav',
        isSyncedEnum: {
          [SyncedStatus.all]: 'Data jsou aktuální',
          [SyncedStatus.some]: 'Některá data nejsou aktuální',
          [SyncedStatus.none]: 'Žádná data nejsou aktuální',
        },
      },
    },
  },
  sk: {
    pointOfSaleSynchronization: {
      fields: {
        titleKey: 'Oblasť',
        titleKeyEnum: {
          [PointOfSaleSynchronizationType.articles]: 'Artikly',
          [PointOfSaleSynchronizationType.promotions]: 'Promócie',
          [PointOfSaleSynchronizationType.posData]: 'Základné údaje POS',
          [PointOfSaleSynchronizationType.configurations]: 'Konfigurácie',
          [PointOfSaleSynchronizationType.cashiers]: 'Pokladníci',
          noData: 'Žiadne údaje',
        },
        isUpToDate: 'Stav',
        isUpToDateEnum: {
          true: 'Aktuálne',
          false: 'Neaktuálne',
        },
        lastAppliedAt: 'Posledné údaje POS',
        nonSyncedRequestedAt: 'Stiahnuté na POS',
        nonSyncedCreatedAt: 'Posledné údaje z MW',
        isSynced: 'Stav',
        isSyncedEnum: {
          [SyncedStatus.all]: 'Všetky údaje sú aktuálne',
          [SyncedStatus.some]: 'Niektoré údaje nie sú aktuálne',
          [SyncedStatus.none]: 'Žiadne údaje nie sú aktuálne',
        },
      },
    },
  },
  en: {
    pointOfSaleSynchronization: {
      fields: {
        titleKey: 'Area',
        titleKeyEnum: {
          [PointOfSaleSynchronizationType.articles]: 'Articles',
          [PointOfSaleSynchronizationType.promotions]: 'Promotions',
          [PointOfSaleSynchronizationType.posData]: 'POS basic data',
          [PointOfSaleSynchronizationType.configurations]: 'Configurations',
          [PointOfSaleSynchronizationType.cashiers]: 'Cashiers',
          noData: 'No data',
        },
        isUpToDate: 'Status',
        isUpToDateEnum: {
          true: 'Up to date',
          false: 'Not up to date',
        },
        lastAppliedAt: 'Last applied at',
        nonSyncedRequestedAt: 'Downloaded to POS',
        nonSyncedCreatedAt: 'Last data from MW',
        isSynced: 'Status',
        isSyncedEnum: {
          [SyncedStatus.all]: 'Data are up to date',
          [SyncedStatus.some]: 'Some data are not up to date',
          [SyncedStatus.none]: 'No data are up to date',
        },
      },
    },
  },
  de: {
    pointOfSaleSynchronization: {
      fields: {
        titleKey: 'Bereich',
        titleKeyEnum: {
          [PointOfSaleSynchronizationType.articles]: 'Artikel',
          [PointOfSaleSynchronizationType.promotions]: 'Aktionen',
          [PointOfSaleSynchronizationType.posData]: 'Grundlegende POS-Daten',
          [PointOfSaleSynchronizationType.configurations]: 'Konfigurationen',
          [PointOfSaleSynchronizationType.cashiers]: 'Kassierer',
          noData: 'Keine Daten',
        },
        isUpToDate: 'Status',
        isUpToDateEnum: {
          true: 'Aktuell',
          false: 'Nicht aktuell',
        },
        lastAppliedAt: 'Letzte POS-Daten',
        nonSyncedRequestedAt: 'Auf POS heruntergeladen',
        nonSyncedCreatedAt: 'Letzte Daten aus MW',
        isSynced: 'Status',
        isSyncedEnum: {
          [SyncedStatus.all]: 'Alle Daten sind aktuell',
          [SyncedStatus.some]: 'Einige Daten sind nicht aktuell',
          [SyncedStatus.none]: 'Keine Daten sind aktuell',
        },
      },
    },
  },
};
