
import {useMenu} from '@ui/helpers/menu';
import {PhIcons} from '@ui/helpers/phIcons';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';


export function useAppMenu ({dashboard = true} = {}) {
  const {sniffMenuState} = useMenu();
  const i18n = useI18n();

  const menu = computed(() => sniffMenuState([
    ...(dashboard ? [
      {
        label: i18n.t('layout.components.aside.menu.dashboard'),
        icon: 'PhHouse' as PhIcons,
        to: {name: 'dashboard'},
      },
    ] : []),
    {
      label: i18n.t('layout.components.aside.menu.shop'),
      icon: 'PhBuildings' as PhIcons,
      to: {name: 'shops'},
      sectionRoutes: [
        'shops',
        'shop',
        'shop-configurations',
        'shop-update',
        'shop-create',
      ],
    },
    {
      label: i18n.t('layout.components.aside.menu.pointOfSale'),
      icon: 'PhDesktop' as PhIcons,
      to: {name: 'points-of-sale'},
      sectionRoutes: [
        'points-of-sale',
        'point-of-sale',
        'point-of-sale-synchronizations',
        'point-of-sale-versions',
        'point-of-sale-configurations',
        'point-of-sale-update',
        'point-of-sale-create',
        'point-of-sale-configuration-update',
        'point-of-sale-configuration-readonly',
        'point-of-sale-configuration-create',
      ],
    },
    {
      label: i18n.t('layout.components.aside.menu.cashier'),
      icon: 'PhIdentificationCard' as PhIcons,
      group: 'groupCashiers',
      items: [
        {
          label: i18n.t('layout.components.aside.menu.cashier'),
          icon: 'PhIdentificationCard' as PhIcons,
          to: {name: 'cashiers'},
          sectionRoutes: [
            'cashiers',
            'cashier',
            'cashier-update',
            'cashier-create',
          ],
        },
        {
          label: i18n.t('layout.components.aside.menu.cashierRole'),
          icon: 'PhIdentificationCard' as PhIcons,
          to: {name: 'cashier-roles'},
          sectionRoutes: [
            'cashier-roles',
            'cashier-role',
            'cashier-role-update',
            'cashier-role-create',
          ],
        },
      ],
    },
    {
      label: i18n.t('layout.components.aside.menu.configuration'),
      icon: 'PhGearSix' as PhIcons,
      group: 'groupConfigurations',
      items: [
        {
          label: i18n.t('layout.components.aside.menu.pointOfSaleConfiguration'),
          icon: 'PhGraph' as PhIcons,
          to: {name: 'configurations'},
          sectionRoutes: [
            'configurations',
            'configuration',
            'configuration-assigned-pos',
            'configuration-update',
            'configuration-create',
          ],
        },
        {
          label: i18n.t('layout.components.aside.menu.middlewareConfiguration'),
          icon: 'PhTreeStructure' as PhIcons,
          to: {name: 'middleware-configuration-update'},
          sectionRoutes: ['middleware-configuration-update'],
        },
        {
          label: i18n.t('layout.components.aside.menu.pointOfSaleAttribute'),
          icon: 'PhPaperclip' as PhIcons,
          to: {name: 'point-of-sale-attributes'},
          sectionRoutes: ['point-of-sale-attributes'],
        },
        {
          label: i18n.t('layout.components.aside.menu.import'),
          icon: 'PhPackage' as PhIcons,
          to: {name: 'imports'},
          sectionRoutes: ['imports'],
        },
      ],
    },
    {
      label: i18n.t('layout.components.aside.menu.promotion'),
      icon: 'PhSpeakerSimpleHigh' as PhIcons,
      group: 'groupPromotions',
      items: [
        {
          label: i18n.t('layout.components.aside.menu.promotion'),
          icon: 'PhSpeakerSimpleHigh' as PhIcons,
          to: {name: 'promotions'},
          sectionRoutes: [
            'promotions',
            'promotion-create',
            'promotion-update',
          ],
        },
        {
          label: i18n.t('layout.components.aside.menu.promotionList'),
          icon: 'PhShoppingCart' as PhIcons,
          to: {name: 'promotion-lists'},
          sectionRoutes: [
            'promotion-lists',
            'promotion-list-create',
            'promotion-list-update',
          ],
        },
      ],
    },

    {
      label: i18n.t('layout.components.aside.menu.voucher'),
      icon: 'PhTicket' as PhIcons,
      group: 'groupVouchers',
      items: [
        {
          label: i18n.t('layout.components.aside.menu.voucherEmission'),
          icon: 'PhPercent' as PhIcons,
          to: {name: 'voucher-emissions'},
          sectionRoutes: [
            'voucher-emissions',
            'voucher-emission',
            'voucher-emission-vouchers',
            'voucher-emission-update',
            'voucher-emission-create',
            'voucher-emission-bulk-create',
          ],
        },
        {
          label: i18n.t('layout.components.aside.menu.voucherOverview'),
          icon: 'PhTicket' as PhIcons,
          to: {name: 'vouchers'},
          sectionRoutes: [
            'vouchers',
            'voucher',
            'voucher-events',
          ],
        },
      ],
    },
    {
      label: i18n.t('layout.components.aside.menu.voucherSearch'),
      icon: 'PhCards' as PhIcons,
      to: {name: 'voucher-search'},
    },

    {
      label: i18n.t('layout.components.aside.menu.transaction'),
      icon: 'PhReceipt' as PhIcons,
      to: {name: 'transactions'},
    },
  ]));

  return {
    menu,
  };
}
