import {Condition} from '@api/posMiddlewareCore/constants/condition';
import {PointOfSaleVersionUpdateType} from '@api/posMiddlewareCore/constants/pointOfSaleVersionUpdateType';

export default {
  cs: {
    pointOfSaleVersion: {
      form: {
        placeholders: {
          version: 'Vybrete verzi',
          type: 'Vyberte typ',
          condition: 'Vyberte podmínku',
        },
      },
      actions: {
        cancelUpdate: {
          title: 'Zrušit aktualizaci',
          confirm: 'Opravdu si přejete zrušit aktualizaci?',
        },
      },
      fields: {
        posVersion: 'Verze',
        created: 'Založeno',
        createdDate: 'Datum založení',
        createdByName: 'Založil',
        latestStatusType: 'Poslední stav',
        latestStatusCreatedAt: 'Datum posledního stavu',
        statusHistory: 'Historie',
        latestStatusDescription: '{createdAt} - {type}',
        cancelled: 'Zrušeno',
        canceledDate: 'Datum zrušení',
        canceledByName: 'Zrušil',
        version: 'Verze',
        validFrom: 'Platné od',
        type: 'Typ',
        typeEnum: {
          [PointOfSaleVersionUpdateType.immediate]: 'Bez souhlasu obsluhy',
          [PointOfSaleVersionUpdateType.onDemand]: 'Vyžaduje souhlas obsluhy',
        },
        condition: 'Podmínka',
        conditionEnum: {
          [Condition.financialCloseDayFinished]: 'Dokončení finanční uzávěrky',
          [Condition.none]: 'Žádná',
        },
      },
    },
  },
  sk: {
    pointOfSaleVersion: {
      form: {
        placeholders: {
          version: 'Vyberte verziu',
          type: 'Vyberte typ',
          condition: 'Vyberte podmienku',
        },
      },
      actions: {
        cancelUpdate: {
          title: 'Zrušiť aktualizáciu',
          confirm: 'Naozaj chcete zrušiť aktualizáciu?',
        },
      },
      fields: {
        posVersion: 'Verzia',
        created: 'Založené',
        createdDate: 'Dátum založenia',
        createdByName: 'Založil',
        latestStatusType: 'Posledný stav',
        latestStatusCreatedAt: 'Dátum posledného stavu',
        statusHistory: 'História',
        latestStatusDescription: '{createdAt} - {type}',
        cancelled: 'Zrušené',
        canceledDate: 'Dátum zrušenia',
        canceledByName: 'Zrušil',
        version: 'Verzia',
        validFrom: 'Platné od',
        type: 'Typ',
        typeEnum: {
          [PointOfSaleVersionUpdateType.immediate]: 'Bez súhlasu obsluhy',
          [PointOfSaleVersionUpdateType.onDemand]: 'Vyžaduje súhlas obsluhy',
        },
        condition: 'Podmienka',
        conditionEnum: {
          [Condition.financialCloseDayFinished]: 'Dokončenie finančnej uzávierky',
          [Condition.none]: 'Žiadna',
        },
      },
    },
  },
  en: {
    pointOfSaleVersion: {
      form: {
        placeholders: {
          version: 'Select version',
          type: 'Select type',
          condition: 'Select condition',
        },
      },
      actions: {
        cancelUpdate: {
          title: 'Cancel update',
          confirm: 'Are you sure you want to cancel update?',
        },
      },
      fields: {
        posVersion: 'Version',
        created: 'Created',
        createdDate: 'Created date',
        createdByName: 'Created by',
        latestStatusType: 'Latest status',
        latestStatusCreatedAt: 'Latest status date',
        statusHistory: 'History',
        latestStatusDescription: '{createdAt} - {type}',
        cancelled: 'Cancelled',
        canceledDate: 'Cancelled date',
        canceledByName: 'Cancelled by',
        version: 'Version',
        validFrom: 'Valid from',
        type: 'Type',
        typeEnum: {
          [PointOfSaleVersionUpdateType.immediate]: 'Without cashier approval',
          [PointOfSaleVersionUpdateType.onDemand]: 'Requires cashier approval',
        },
        condition: 'Condition',
        conditionEnum: {
          [Condition.financialCloseDayFinished]: 'Financial close day finished',
          [Condition.none]: 'None',
        },
      },
    },
  },
  de: {
    pointOfSaleVersion: {
      form: {
        placeholders: {
          version: 'Version auswählen',
          type: 'Typ auswählen',
          condition: 'Bedingung auswählen',
        },
      },
      actions: {
        cancelUpdate: {
          title: 'Aktualisierung abbrechen',
          confirm: 'Möchten Sie die Aktualisierung wirklich abbrechen?',
        },
      },
      fields: {
        posVersion: 'Version',
        created: 'Erstellt',
        createdDate: 'Erstellungsdatum',
        createdByName: 'Erstellt von',
        latestStatusType: 'Letzter Status',
        latestStatusCreatedAt: 'Datum des letzten Status',
        statusHistory: 'Verlauf',
        latestStatusDescription: '{createdAt} - {type}',
        cancelled: 'Abgebrochen',
        canceledDate: 'Datum der Abbruch',
        canceledByName: 'Abgebrochen von',
        version: 'Version',
        validFrom: 'Gültig ab',
        type: 'Typ',
        typeEnum: {
          [PointOfSaleVersionUpdateType.immediate]: 'Ohne Zustimmung des Bedieners',
          [PointOfSaleVersionUpdateType.onDemand]: 'Benutzerzustimmung erforderlich',
        },
        condition: 'Bedingung',
        conditionEnum: {
          [Condition.financialCloseDayFinished]: 'Finanzieller Abschluss abgeschlossen',
          [Condition.none]: 'Keine',
        },
      },
    },
  },
};
