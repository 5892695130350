
import {HLField} from '@designeo/vue-forms';
import {injectFormContext} from '@designeo/vue-forms/src/Form/Form';
import {useFilters} from '@ui/helpers/filters';
import {provideFormInputContext} from '@ui/helpers/form';
import {
  flow,
  get,
  isObject,
  some,
} from 'lodash-es';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  components: {
    HLField,
  },
  inheritAttrs: false,
  props: {
    field: {
      type: String,
      required: true,
    },
    displayErrors: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const {stringifyFormat} = useFilters();
    const {validationSchema, disabled, readonly} = injectFormContext();

    const required = computed(() => {
      if (!validationSchema.value) {
        return false;
      }

      return flow([
        (description) => get(description.fields, props.field, null),
        (field) => {
          if (!field) {
            return false;
          }

          return some(field.tests, {name: 'required'});
        },
      ])(validationSchema.value.describe());
    });

    provideFormInputContext(props.field);

    const isTypeJson = (val) => {
      return isObject(val);
    };

    return {
      stringifyFormat,
      isTypeJson,
      required,
      disabled,
      readonly,
    };
  },
});
