export default {
  cs: {
    cashier: {
      detail: {
        title: '#{personalNumber}',
        pinCode: {
          forceChangeMessage: 'Uživatel si musí při příštím přihlášení změnit PIN',
        },
      },
      grid: {
        title: 'Pokladní',
        actions: {
          create: {
            title: 'Založit',
          },
          pinChange: {
            title: 'Vynutit změnu pinu',
            confirm: 'Opravdu si přejete vynutit změnu pinu?',
          },
          activate: {
            confirm: 'Opravdu chcete pokladní aktivovat?',
          },
          deactivate: {
            confirm: 'Opravdu chcete pokladní deaktivovat?',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          assignedRoles: 'Role',
          assignedRolesEnum: {
            allOptions: 'Všechny role',
          },
          isValid: 'Platnost',
          isValidEnum: {
            allOptions: 'Všechny platnosti',
          },
          isActive: 'Aktivní',
          isActiveEnum: {
            allOptions: 'Všechny stavy',
          },
        },
        advancedFilters: {
          title: 'Filtrovat pokladní',
          search: 'Vyhledat',
          placeholders: {
            search: 'Zadejte název (min. 2 znaky)',
            assignedRoles: 'Vyberte role',
            isValid: 'Vyberte platnost',
            isActive: 'Vyberte stav',
          },
        },
      },
      form: {
        create: {
          title: 'Nový/á pokladní',
        },
        update: {
          title: 'Úprava pokladní/ho ',
        },
        placeholders: {
          personalNumber: 'Zadejte osobní číslo',
          firstName: 'Zadejte jméno',
          lastName: 'Zadejte příjmení',
          email: 'Zadejte email',
          phone: 'Zadejte telefon',
          assignedRoles: 'Vyberte role',
          pinCode: 'Zadejte pin',
          primaryShop: 'Zadejte primární shop',
        },
      },
      fields: {
        id: 'ID',
        personalNumber: 'Osobní číslo',
        firstName: 'Jméno',
        lastName: 'Příjmení',
        name: 'Jméno',
        pinCode: 'PIN',
        email: 'E-mail',
        phone: 'Telefon',
        primaryShop: 'Primární prodejna',
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        isValid: 'Platný',
        isValidEnum: {
          true: 'Platný',
          false: 'Neplatný',
        },
        isActive: 'Aktivní',
        isActiveEnum: {
          true: 'Aktivní',
          false: 'Neaktivní',
        },
        state: 'Stav',
        assignedRoles: 'Role',
      },
    },
  },
  sk: {
    cashier: {
      detail: {
        title: '#{personalNumber}',
        pinCode: {
          forceChangeMessage: 'Používateľ musí zmeniť PIN pri ďalšom prihlásení',
        },
      },
      grid: {
        title: 'Pokladník',
        actions: {
          create: {
            title: 'Vytvoriť',
          },
          pinChange: {
            title: 'Vynútiť zmenu PIN',
            confirm: 'Naozaj chcete vynútiť zmenu PIN?',
          },
          activate: {
            confirm: 'Naozaj chcete aktivovať pokladníka?',
          },
          deactivate: {
            confirm: 'Naozaj chcete deaktivovať pokladníka?',
          },
        },
        filters: {
          search: 'Vyhľadať (min. 2 znaky)',
          assignedRoles: 'Role',
          assignedRolesEnum: {
            allOptions: 'Všetky role',
          },
          isValid: 'Platnosť',
          isValidEnum: {
            allOptions: 'Všetky platnosti',
          },
          isActive: 'Aktívny',
          isActiveEnum: {
            allOptions: 'Všetky stavy',
          },
        },
        advancedFilters: {
          title: 'Filtrovať pokladníka',
          search: 'Vyhľadať',
          placeholders: {
            search: 'Zadajte názov (min. 2 znaky)',
            assignedRoles: 'Vyberte role',
            isValid: 'Vyberte platnosť',
            isActive: 'Vyberte stav',
          },
        },
      },
      form: {
        create: {
          title: 'Nový pokladník',
        },
        update: {
          title: 'Úprava pokladníka',
        },
        placeholders: {
          personalNumber: 'Zadajte osobné číslo',
          firstName: 'Zadajte meno',
          lastName: 'Zadajte priezvisko',
          email: 'Zadajte email',
          phone: 'Zadajte telefón',
          assignedRoles: 'Vyberte role',
          pinCode: 'Zadajte PIN',
          primaryShop: 'Zadajte primárny obchod',
        },
      },
      fields: {
        id: 'ID',
        personalNumber: 'Osobné číslo',
        firstName: 'Meno',
        lastName: 'Priezvisko',
        name: 'Meno',
        pinCode: 'PIN',
        email: 'E-mail',
        phone: 'Telefón',
        primaryShop: 'Primárny obchod',
        validFrom: 'Platnosť od',
        validTill: 'Platnosť do',
        isValid: 'Platný',
        isValidEnum: {
          true: 'Platný',
          false: 'Neplatný',
        },
        isActive: 'Aktívny',
        isActiveEnum: {
          true: 'Aktívny',
          false: 'Neaktívny',
        },
        state: 'Stav',
        assignedRoles: 'Role',
      },
    },
  },
  en: {
    cashier: {
      detail: {
        title: '#{personalNumber}',
        pinCode: {
          forceChangeMessage: 'User must change PIN on next login',
        },
      },
      grid: {
        title: 'Cashier',
        actions: {
          create: {
            title: 'Create',
          },
          pinChange: {
            title: 'Force PIN change',
            confirm: 'Are you sure you want to force PIN change?',
          },
          activate: {
            confirm: 'Are you sure you want to activate cashier?',
          },
          deactivate: {
            confirm: 'Are you sure you want to deactivate cashier?',
          },
        },
        filters: {
          search: 'Search (min. 2 characters)',
          assignedRoles: 'Roles',
          assignedRolesEnum: {
            allOptions: 'All roles',
          },
          isValid: 'Validity',
          isValidEnum: {
            allOptions: 'All validities',
          },
          isActive: 'Active',
          isActiveEnum: {
            allOptions: 'All states',
          },
        },
        advancedFilters: {
          title: 'Filter cashier',
          search: 'Search',
          placeholders: {
            search: 'Enter name (min. 2 characters)',
            assignedRoles: 'Select roles',
            isValid: 'Select validity',
            isActive: 'Select state',
          },
        },
      },
      form: {
        create: {
          title: 'New cashier',
        },
        update: {
          title: 'Edit cashier',
        },
        placeholders: {
          personalNumber: 'Enter personal number',
          firstName: 'Enter first name',
          lastName: 'Enter last name',
          email: 'Enter email',
          phone: 'Enter phone',
          assignedRoles: 'Select roles',
          pinCode: 'Enter PIN',
          primaryShop: 'Enter primary shop',
        },
      },
      fields: {
        id: 'ID',
        personalNumber: 'Personal number',
        firstName: 'First name',
        lastName: 'Last name',
        name: 'Name',
        pinCode: 'PIN',
        email: 'Email',
        phone: 'Phone',
        primaryShop: 'Primary shop',
        validFrom: 'Valid from',
        validTill: 'Valid till',
        isValid: 'Valid',
        isValidEnum: {
          true: 'Valid',
          false: 'Invalid',
        },
        isActive: 'Active',
        isActiveEnum: {
          true: 'Active',
          false: 'Inactive',
        },
        state: 'State',
        assignedRoles: 'Roles',
      },
    },
  },
  de: {
    cashier: {
      detail: {
        title: '#{personalNumber}',
        pinCode: {
          forceChangeMessage: 'Benutzer muss das PIN bei der nächsten Anmeldung ändern',
        },
      },
      grid: {
        title: 'Kassierer',
        actions: {
          create: {
            title: 'Erstellen',
          },
          pinChange: {
            title: 'PIN-Änderung erzwingen',
            confirm: 'Sind Sie sicher, dass Sie die PIN-Änderung erzwingen möchten?',
          },
          activate: {
            confirm: 'Sind Sie sicher, dass Sie den Kassierer aktivieren möchten?',
          },
          deactivate: {
            confirm: 'Sind Sie sicher, dass Sie den Kassierer deaktivieren möchten?',
          },
        },
        filters: {
          search: 'Suche (min. 2 Zeichen)',
          assignedRoles: 'Rollen',
          assignedRolesEnum: {
            allOptions: 'Alle Rollen',
          },
          isValid: 'Gültigkeit',
          isValidEnum: {
            allOptions: 'Alle Gültigkeiten',
          },
          isActive: 'Aktiv',
          isActiveEnum: {
            allOptions: 'Alle Zustände',
          },
        },
        advancedFilters: {
          title: 'Kassierer filtern',
          search: 'Suche',
          placeholders: {
            search: 'Name eingeben (min. 2 Zeichen)',
            assignedRoles: 'Rollen auswählen',
            isValid: 'Gültigkeit auswählen',
            isActive: 'Zustand auswählen',
          },
        },
      },
      form: {
        create: {
          title: 'Neuer Kassierer',
        },
        update: {
          title: 'Kassierer bearbeiten',
        },
        placeholders: {
          personalNumber: 'Persönliche Nummer eingeben',
          firstName: 'Vorname eingeben',
          lastName: 'Nachname eingeben',
          email: 'E-Mail eingeben',
          phone: 'Telefon eingeben',
          assignedRoles: 'Rollen auswählen',
          pinCode: 'PIN eingeben',
          primaryShop: 'Primärer Shop eingeben',
        },
      },
      fields: {
        id: 'ID',
        personalNumber: 'Persönliche Nummer',
        firstName: 'Vorname',
        lastName: 'Nachname',
        name: 'Name',
        pinCode: 'PIN',
        email: 'E-Mail',
        phone: 'Telefon',
        primaryShop: 'Primärer Shop',
        validFrom: 'Gültig ab',
        validTill: 'Gültig bis',
        isValid: 'Gültig',
        isValidEnum: {
          true: 'Gültig',
          false: 'Ungültig',
        },
        isActive: 'Aktiv',
        isActiveEnum: {
          true: 'Aktiv',
          false: 'Inaktiv',
        },
        state: 'Status',
        assignedRoles: 'Rollen',
      },
    },
  },
};
