import {PointOfSaleUpdateStatusMessageCode} from '@api/posMiddlewareCore/constants/pointOfSaleUpdateStatusMessageCode';
import {PointOfSaleUpdateStatusType} from '@api/posMiddlewareCore/constants/pointOfSaleUpdateStatusType';

export default {
  cs: {
    updateStatus: {
      grid: {
        cells: {
          lastVersion: {
            history: 'Historie',
            description: '{createdAt} - {type}',
            typeConsolidated: {
              planned: 'Naplánováno',
              running: 'Probíhá aktualizace',
              cancelled: 'Zrušeno',
              success: 'Úspěšně nainstalováno',
            },
          },
        },
      },
      fields: {
        typeEnum: {
          [PointOfSaleUpdateStatusType.archivedByRegistration]: 'Archivovaný při registraci',
          [PointOfSaleUpdateStatusType.planned]: 'Update byl naplánován',
          [PointOfSaleUpdateStatusType.synced]: 'POSka update přijala',
          [PointOfSaleUpdateStatusType.waitingForCapacity]: 'Čeká se na stažení ve frontě',
          [PointOfSaleUpdateStatusType.downloading]: 'Stahuje se',
          [PointOfSaleUpdateStatusType.downloaded]: 'Byl stáhnut',
          [PointOfSaleUpdateStatusType.cancelPlanned]: 'Zrušení updatu bylo naplánováno',
          [PointOfSaleUpdateStatusType.cancelled]: 'Update byl zrušen na POSce',
          [PointOfSaleUpdateStatusType.updating]: 'Update byl spuštěn',
          [PointOfSaleUpdateStatusType.updated]: 'Update byl nainstalován úspěšně',
          [PointOfSaleUpdateStatusType.updateFailed]: 'Update se nepodařilo nainstalovat',
          [PointOfSaleUpdateStatusType.downloadFailed]: 'Stažení se nezdařilo',
          [PointOfSaleUpdateStatusType.updateNotStarted]: 'Nesplněny podmínky pro update',
          none: 'Žádný',
        },
        messageCodeEnum: {
          [PointOfSaleUpdateStatusMessageCode.streamReadTimeout]: 'Vypršel čas při čtení streamu z centrálního serveru.',
          [PointOfSaleUpdateStatusMessageCode.dateTimeConditionNotFulfilled]: 'Nebyla splněna časová podmínka.',
          [PointOfSaleUpdateStatusMessageCode.transactionSyncConditionNotFulfilled]: 'Některé transakce nejsou synchronizované na centrální server.',
          [PointOfSaleUpdateStatusMessageCode.financialCloseDayConditionNotFulfilled]: 'Nebyla provedena finanční uzávěrka.',
          [PointOfSaleUpdateStatusMessageCode.existingInstallerFileConditionNotFulfilled]: 'Soubor s novou aktualizací neexistuje.',
          [PointOfSaleUpdateStatusMessageCode.existingUpdateConditionNotFulfilled]: 'Není nastavený žádný update.',
          [PointOfSaleUpdateStatusMessageCode.correctDataFormatConditionNotFulfilled]: 'Nepodařilo se přečíst formát nastaveného datumu.',
        },
      },
    },
  },
  sk: {
    updateStatus: {
      grid: {
        cells: {
          lastVersion: {
            history: 'História',
            description: '{createdAt} - {type}',
            typeConsolidated: {
              planned: 'Naplánované',
              running: 'Prebieha aktualizácia',
              cancelled: 'Zrušené',
              success: 'Úspešne nainštalované',
            },
          },
        },
      },
      fields: {
        typeEnum: {
          [PointOfSaleUpdateStatusType.archivedByRegistration]: 'Archivované pri registrácii',
          [PointOfSaleUpdateStatusType.planned]: 'Aktualizácia bola naplánovaná',
          [PointOfSaleUpdateStatusType.synced]: 'Aktualizácia POS akceptovala',
          [PointOfSaleUpdateStatusType.waitingForCapacity]: 'Čaká sa na stiahnutie vo fronte',
          [PointOfSaleUpdateStatusType.downloading]: 'Sťahuje sa',
          [PointOfSaleUpdateStatusType.downloaded]: 'Bolo stiahnuté',
          [PointOfSaleUpdateStatusType.cancelPlanned]: 'Zrušenie aktualizácie bolo naplánované',
          [PointOfSaleUpdateStatusType.cancelled]: 'Aktualizácia bola zrušená na POS',
          [PointOfSaleUpdateStatusType.updating]: 'Aktualizácia bola spustená',
          [PointOfSaleUpdateStatusType.updated]: 'Aktualizácia bola úspešne nainštalovaná',
          [PointOfSaleUpdateStatusType.updateFailed]: 'Aktualizáciu sa nepodarilo nainštalovať',
          [PointOfSaleUpdateStatusType.downloadFailed]: 'Stiahnutie sa nepodarilo',
          [PointOfSaleUpdateStatusType.updateNotStarted]: 'Podmienky pre aktualizáciu nesplnené',
          none: 'Žiadne',
        },
        messageCodeEnum: {
          [PointOfSaleUpdateStatusMessageCode.streamReadTimeout]: 'Vypršal čas pri čítaní prúdu zo servera.',
          [PointOfSaleUpdateStatusMessageCode.dateTimeConditionNotFulfilled]: 'Neplnená časová podmienka.',
          [PointOfSaleUpdateStatusMessageCode.transactionSyncConditionNotFulfilled]: 'Niektoré transakcie nie sú synchronizované na server.',
          [PointOfSaleUpdateStatusMessageCode.financialCloseDayConditionNotFulfilled]: 'Nebola vykonaná finančná uzávierka.',
          [PointOfSaleUpdateStatusMessageCode.existingInstallerFileConditionNotFulfilled]: 'Súbor s novou aktualizáciou neexistuje.',
          [PointOfSaleUpdateStatusMessageCode.existingUpdateConditionNotFulfilled]: 'Nie je nastavená žiadna aktualizácia.',
          [PointOfSaleUpdateStatusMessageCode.correctDataFormatConditionNotFulfilled]: 'Nepodarilo sa prečítať formát nastaveného dátumu.',
        },
      },
    },
  },
  en: {
    updateStatus: {
      grid: {
        cells: {
          lastVersion: {
            history: 'History',
            description: '{createdAt} - {type}',
            typeConsolidated: {
              planned: 'Planned',
              running: 'Update in progress',
              cancelled: 'Cancelled',
              success: 'Successfully installed',
            },
          },
        },
      },
      fields: {
        typeEnum: {
          [PointOfSaleUpdateStatusType.archivedByRegistration]: 'Archived by registration',
          [PointOfSaleUpdateStatusType.planned]: 'Update was planned',
          [PointOfSaleUpdateStatusType.synced]: 'POSka update accepted',
          [PointOfSaleUpdateStatusType.waitingForCapacity]: 'Waiting for download in queue',
          [PointOfSaleUpdateStatusType.downloading]: 'Downloading',
          [PointOfSaleUpdateStatusType.downloaded]: 'Was downloaded',
          [PointOfSaleUpdateStatusType.cancelPlanned]: 'Update cancellation was planned',
          [PointOfSaleUpdateStatusType.cancelled]: 'Update was cancelled on POSka',
          [PointOfSaleUpdateStatusType.updating]: 'Update was started',
          [PointOfSaleUpdateStatusType.updated]: 'Update was successfully installed',
          [PointOfSaleUpdateStatusType.updateFailed]: 'Update failed to install',
          [PointOfSaleUpdateStatusType.downloadFailed]: 'Download failed',
          [PointOfSaleUpdateStatusType.updateNotStarted]: 'Update conditions not fulfilled',
          none: 'None',
        },
        messageCodeEnum: {
          [PointOfSaleUpdateStatusMessageCode.streamReadTimeout]: 'Stream read timeout from central server.',
          [PointOfSaleUpdateStatusMessageCode.dateTimeConditionNotFulfilled]: 'Date time condition not fulfilled.',
          [PointOfSaleUpdateStatusMessageCode.transactionSyncConditionNotFulfilled]: 'Some transactions are not synchronized to central server.',
          [PointOfSaleUpdateStatusMessageCode.financialCloseDayConditionNotFulfilled]: 'Financial close day not performed.',
          [PointOfSaleUpdateStatusMessageCode.existingInstallerFileConditionNotFulfilled]: 'File with new update does not exist.',
          [PointOfSaleUpdateStatusMessageCode.existingUpdateConditionNotFulfilled]: 'No update is set.',
          [PointOfSaleUpdateStatusMessageCode.correctDataFormatConditionNotFulfilled]: 'Failed to read the format of the set date.',
        },
      },
    },
  },
  de: {
    updateStatus: {
      grid: {
        cells: {
          lastVersion: {
            history: 'Verlauf',
            description: '{createdAt} - {type}',
            typeConsolidated: {
              planned: 'Geplant',
              running: 'Aktualisierung läuft',
              cancelled: 'Abgebrochen',
              success: 'Erfolgreich installiert',
            },
          },
        },
      },
      fields: {
        typeEnum: {
          [PointOfSaleUpdateStatusType.archivedByRegistration]: 'Archiviert bei der Registrierung',
          [PointOfSaleUpdateStatusType.planned]: 'Update wurde geplant',
          [PointOfSaleUpdateStatusType.synced]: 'POS hat das Update akzeptiert',
          [PointOfSaleUpdateStatusType.waitingForCapacity]: 'Wartet auf Download in der Warteschlange',
          [PointOfSaleUpdateStatusType.downloading]: 'Wird heruntergeladen',
          [PointOfSaleUpdateStatusType.downloaded]: 'Wurde heruntergeladen',
          [PointOfSaleUpdateStatusType.cancelPlanned]: 'Abbruch des Updates wurde geplant',
          [PointOfSaleUpdateStatusType.cancelled]: 'Update wurde auf POS abgebrochen',
          [PointOfSaleUpdateStatusType.updating]: 'Update wurde gestartet',
          [PointOfSaleUpdateStatusType.updated]: 'Update wurde erfolgreich installiert',
          [PointOfSaleUpdateStatusType.updateFailed]: 'Update konnte nicht installiert werden',
          [PointOfSaleUpdateStatusType.downloadFailed]: 'Download fehlgeschlagen',
          [PointOfSaleUpdateStatusType.updateNotStarted]: 'Bedingungen für das Update nicht erfüllt',
          none: 'Keine',
        },
        messageCodeEnum: {
          [PointOfSaleUpdateStatusMessageCode.streamReadTimeout]: 'Zeitüberschreitung beim Lesen des Datenstroms vom zentralen Server.',
          [PointOfSaleUpdateStatusMessageCode.dateTimeConditionNotFulfilled]: 'Zeitbedingung nicht erfüllt.',
          [PointOfSaleUpdateStatusMessageCode.transactionSyncConditionNotFulfilled]: 'Einige Transaktionen sind nicht auf dem zentralen Server synchronisiert.',
          [PointOfSaleUpdateStatusMessageCode.financialCloseDayConditionNotFulfilled]: 'Finanzabschlussbedingung nicht erfüllt.',
          [PointOfSaleUpdateStatusMessageCode.existingInstallerFileConditionNotFulfilled]: 'Die Datei mit dem neuen Update existiert nicht.',
          [PointOfSaleUpdateStatusMessageCode.existingUpdateConditionNotFulfilled]: 'Es ist kein Update festgelegt.',
          [PointOfSaleUpdateStatusMessageCode.correctDataFormatConditionNotFulfilled]: 'Das Format des festgelegten Datums konnte nicht gelesen werden.',
        },
      },
    },
  },
};
