export enum TestEvent {
  routerNavigation = 'routerNavigation',
  modalClosed = 'modalClosed',
  modalOpened = 'modalOpened',
  notificationOpened = 'notificationOpened',
  notificationClosed = 'notificationClosed',
  error = 'error',
  interactionResultPositive = 'interactionResultPositive',
  interactionResultNegative = 'interactionResultNegative',
  gridFetched = 'gridFetched',
  detailFetched = 'detailFetched',
  selectOptionsFetched = 'selectOptionsFetched',
  monacoEditorLoaded = 'monacoEditorLoaded',
  methodFinished = 'methodFinished',
}

export enum TestEventPayload {

}
