import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "flex-shrink flex flex-col border-l input-border-color"
}
const _hoisted_3 = { class: "input-warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonacoEditor = _resolveComponent("MonacoEditor")!
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_HLInputText = _resolveComponent("HLInputText")!
  const _component_ModalJsonEditHelp = _resolveComponent("ModalJsonEditHelp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HLInputText, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event)),
      strictVModel: _ctx.strictVModel
    }, {
      default: _withCtx(({ model: hlModel }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["input grid px-0 overflow-hidden", [
          {
            'grid-cols-[1fr_3rem]': _ctx.showHelp,
            'input--focus': _ctx.focused,
            'input--valid': _ctx.valid,
            'input--invalid': !_ctx.valid,
            'input--disabled': _ctx.disabled,
          }
        ]])
          }, [
            _createVNode(_component_MonacoEditor, _mergeProps({
              modelValue: hlModel.value,
              "onUpdate:modelValue": ($event: any) => ((hlModel.value) = $event),
              readonly: _ctx.disabled,
              schema: _ctx.schema,
              [(_ctx.$test.field) || ""]: _ctx.field
            }, _ctx.$attrs, {
              onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focused = true)),
              onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = false))
            }), null, 16, ["modelValue", "onUpdate:modelValue", "readonly", "schema"]),
            (_ctx.showHelp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("button", {
                    class: "btn-white bg-transparent border-transparent",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(() => _ctx.onHelpClicked(), ["prevent"]))
                  }, [
                    _createVNode(_component_PhIcon, {
                      icon: "PhQuestion",
                      weight: "bold"
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_3, " ! ", 512), [
                [_vShow, !_ctx.valid]
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "strictVModel"]),
    _createVNode(_component_ModalJsonEditHelp, {
      entity: _ctx.modalEditHelp,
      "onUpdate:entity": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalEditHelp) = $event))
    }, null, 8, ["entity"])
  ], 64))
}