import {sentryFinishCurrentTransaction} from '@ui/helpers/sentry';
import {
  isEmpty,
} from 'lodash-es';
import {
  Composer,
  useI18n,
} from 'vue-i18n';
import {getVueApp} from './app';
import {scenarioIdSessionStorage} from './persist';

export const vm = () => {
  return getVueApp();
};

export const mountDevTools = () => {
  const i18n = useI18n();

  Object.defineProperty(window, 'scenarioId', {
    get() {
      return scenarioIdSessionStorage.get();
    },
    set(v: any) {
      return scenarioIdSessionStorage.set(v);
    },
  });

  Object.defineProperty(window, 'finishCurrentSentryTransaction', {
    value: () => {
      sentryFinishCurrentTransaction();
    },
  });

  Object.defineProperty(window, 'toggleTranslationKeys', {
    value: () => {
      toggleTranslationKeys(i18n);
    },
  });

  window.document.addEventListener('keydown', (e) => {
    if (e.key === 'F11' && e.shiftKey) {
      toggleTranslationKeys(i18n);
    }
  });
};

let messages = {};
// todo: get rid of i18n - (useI18n() can be called only at root of setup function)
export const toggleTranslationKeys = (i18n: Composer) => {
  if (!isEmpty(messages)) {
    for (const locale of i18n.availableLocales) {
      i18n.setLocaleMessage(locale, messages[locale]);
    }
    messages = {};
  } else {
    for (const locale of i18n.availableLocales) {
      const localeTranslations = i18n.getLocaleMessage(locale) ?? {};

      if (!isEmpty(localeTranslations)) {
        messages = Object.assign(messages, {[locale]: localeTranslations});
      }

      i18n.setLocaleMessage(locale, {});
    }
  }
};
