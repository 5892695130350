import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalConfirmContent = _resolveComponent("ModalConfirmContent")!

  return (_openBlock(), _createBlock(_component_ModalConfirmContent, {
    hasRejectBtn: _ctx.hasRejectFn,
    type: _ctx.type,
    onClose: _ctx.onReject,
    onConfirm: _ctx.onConfirm,
    onReject: _ctx.onReject
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.coreStore.confirmContent.value && _ctx.coreStore.confirmContent.value.message), 1)
    ]),
    _: 1
  }, 8, ["hasRejectBtn", "type", "onClose", "onConfirm", "onReject"]))
}